import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgxCurrencyModule } from "ngx-currency";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialDesignModule } from "../../material-design/material-design.module";

import { AppHeaderComponent } from "../components/app-header/app-header.component";
import { AppFooterComponent } from "../components/app-footer/app-footer.component";

import { SAPSearchComponent } from "./views/sap-search/sap-search.component";
import { ProfilesComponent } from "./views/profiles/profiles.component";
import { EditPricingComponent } from "./views/edit-pricing/edit-pricing.component";
import { EditPricingCAComponent } from "./views/edit-pricing-ca/edit-pricing-ca.component";
import { EnrollComponent } from "./views/enroll/enroll.component";
import { DoneComponent } from "./views/done/done.component";
import { AccountInfoComponent } from "./views/account-info/account-info.component";
import { CustomPageComponent } from "./views/custom-page/custom-page.component";
import { TestMixComponent } from "./views/testmix/testmix.component";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";

import { TestComponent } from "./components/test-comp/test-comp.component";

import { SharedModule } from "../shared/shared.module";

import { ClientRoutingModule } from "./client-routing.module";

import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";

import { BuildProfileComponent } from "./views/build-profile/build-profile.component";

import { ClientFooterComponent } from "./components/client-footer/client-footer.component";
import { ClientHeaderComponent } from "./components/client-header/client-header.component";
import { ClientLayoutComponent } from "./layout/client-layout/client-layout.component";
import { HomeComponent } from "./views/home/home.component";
import { ProfileCategoryComponent } from "./components/profile-category/profile-category.component";

import { PCCAlertService } from "../service/alert.service";
import { AngularSplitModule } from "angular-split";
import { SessionPromptComponent } from "./components/session-prompt/session-prompt.component";
import { TrainingWatermarkComponent } from "./components/training-watermark/training-watermark.component";
import { LandingComponent } from "./views/landing/landing.component";
import { PcssSliderComponent } from "./components/pcss-slider/pcss-slider.component";
import { GoalSettingComponent } from "./views/goal-setting/goal-setting.component";
import { PcssGoalCalculatorComponent } from "./components/goal-calculator/pcss-goal-calculator.component";

import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";

import { PetOwnerPricingComponent } from "./views/pet-owner-pricing/pet-owner-pricing.component";
import { ProfilesTabPanelComponent } from "./components/profiles-tab-panel/profiles-tab-panel.component";
import { LanguageToggleComponent } from "./components/language-toggle/language-toggle.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { SeismicDocsPanelComponent } from "./components/seismic-docs-panel/seismic-docs-panel.component";
import { SeismicThumbnailComponent } from "./components/seismic-thumbnail/seismic-thumbnail.component";
import { CarouselWrapperComponent } from "./components/carousel-wrapper/carousel-wrapper.component";
import { SessionConfirmComponent } from "./views/session-confirm/session-confirm.component";
import { RecentSearchesComponent } from "./components/recent-searches/recent-searches.component";
import { LockedEnrollmentMessageComponent } from './components/locked-enrollment-message/locked-enrollment-message.component';
import { EnrollPetCareHeroesComponent } from './components/enroll-pet-care-heroes/enroll-pet-care-heroes.component';

@NgModule({
    declarations: [
        AppHeaderComponent,
        AppFooterComponent,
        SAPSearchComponent,
        ProfilesComponent,
        EditPricingComponent,
        EditPricingCAComponent,
        EnrollComponent,
        DoneComponent,
        AccountInfoComponent,
        CustomPageComponent,
        ConfirmationComponent,
        TestMixComponent,
        BuildProfileComponent,
        TestComponent,
        ProgressBarComponent,
        ClientFooterComponent,
        ClientHeaderComponent,
        ClientLayoutComponent,
        HomeComponent,
        ProfileCategoryComponent,
        GoalSettingComponent,
        PetOwnerPricingComponent,
        PcssGoalCalculatorComponent,
        PcssSliderComponent,
        SessionPromptComponent,
        TrainingWatermarkComponent,
        LandingComponent,
        ProfilesTabPanelComponent,
        LanguageToggleComponent,
        CarouselComponent,
        SeismicDocsPanelComponent,
        SeismicThumbnailComponent,
        CarouselWrapperComponent,
        SessionConfirmComponent,
        RecentSearchesComponent,
        LockedEnrollmentMessageComponent,
        EnrollPetCareHeroesComponent
    ],
    imports: [
        TranslateModule,
        NgxCurrencyModule,
        AngularSplitModule,
        SharedModule,
        FormsModule,
        CommonModule,
        ClientRoutingModule,
        MaterialDesignModule,
        NgxExtendedPdfViewerModule
    ],
    providers: [
        PCCAlertService
    ],
    entryComponents: [
        SAPSearchComponent,
        ConfirmationComponent,
        BuildProfileComponent,
        ProfilesComponent,
        AccountInfoComponent,
        TestMixComponent,
        EditPricingComponent,
        EditPricingCAComponent
    ],
    exports: [
        ProgressBarComponent,
        TestComponent,
        ClientFooterComponent,
        ClientHeaderComponent,
        NgxExtendedPdfViewerModule,
        TrainingWatermarkComponent,
        AppHeaderComponent,
        AppFooterComponent
    ]
})
export class ClientModule { }
