import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { ErrorUtils } from "@shared/model/error/error-utils";
import { AdminFacade } from "../../../facade/admin.facade";
import { EnrollUtils, IEnrollment } from "@shared/model/enrollment";
import { IEnrollResponse } from "@shared/model/service/enroll-service";
import { IProfile } from "@shared/model/profile";
import { PCCSession } from "@shared/model/pcc-session";

import { PCCAlertService } from "../../../service/alert.service";
import { DateAdapter } from "@angular/material/core";

@Component({
    selector: "pcc-enrollment-edit",
    templateUrl: "./enrollment-edit.component.html",
    styleUrls: [
        "./enrollment-edit.component.scss"
    ]
})
export class EnrollmentEditComponent implements OnInit {
    public enrollInfo: IEnrollment;

    public saveEnabled = true;

    public saving = false;

    public session: PCCSession;

    public isCorp = false;

    public profiles: IProfile[];

    public constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private adminFacade: AdminFacade,
        private alertService: PCCAlertService,
        private dateAdapter: DateAdapter<any>
    ) {
    }

    public ngOnInit(): void {
        this.session = this.adminFacade.getSession();

        this.activatedRoute.paramMap.subscribe((params): void => {
            this.retrieveEnrollment(params.get("id"));
        });
    }

    public retrieveEnrollment(id: string): void {
        console.log("retrieveEnrollment: ", id);

        this.adminFacade.getEnrollment(id).subscribe(
            (resp: IEnrollResponse): void => {
                console.log("getEnrollment resp: ", resp);
                if (resp.success === true) {
                    this.enrollInfo = resp.enrollInfo;

                    this.isCorp = this.isCorporateEnrollment(this.enrollInfo);
                    this.dateAdapter.setLocale(this.enrollInfo.locale);

                    this.profiles = this.getActiveProfiles(this.enrollInfo.profiles);
                } else {
                    console.error("Error retrieving enrollment: ", resp);
                }
            },
            (err: Error): void => {
                console.error("Error getting enrollment: ", err);
            }
        );

    }

    private getActiveProfiles(profiles: IProfile[]): IProfile[] {
        return profiles.filter((profile: IProfile): boolean =>
            profile.selected
        );
    }

    public launchDateChanged(): void {
        EnrollUtils.updateEnrollmentEndDate(this.enrollInfo);
    }

    public getPDF(): void {
        this.adminFacade.showPdf(this.enrollInfo.enrollment_id);
    }

    public cancel(): void {
        console.log("cancel");
        this.router.navigate([
            "/admin/enrollments"
        ]);
    }

    public async save(): Promise<void> {
        console.log("save");
        this.saving = true;

        this.alertService.setBusy(true, "Saving...");

        try {
            const resp = await this.adminFacade.saveEnrollment(this.enrollInfo);
            console.log("saveEnrollment resp: ", resp);
            this.handleSaveResponse(resp);
        } catch (err) {
            this.alertService.showError("Save failed", ErrorUtils.getErrorMessage(err));
        } finally {
            this.saving = false;
            this.alertService.setBusy(false);
        }
    }

    private handleSaveResponse(resp: IEnrollResponse): void {
        if (resp.success) {
            this.alertService.showToast("Save successful");
            this.profiles = this.getActiveProfiles(resp.enrollInfo.profiles);
        } else if (resp.error && resp.error.message) {
            this.alertService.showError("Save failed", ErrorUtils.getErrorMessage(resp.error));
        } else {
            this.alertService.showError("Save failed");
        }
    }

    public markDelete(): void {
        console.log("markDelete");
        this.enrollInfo.marked_for_deletion = !this.enrollInfo.marked_for_deletion;
    }

    public markProfileDelete(prof: IProfile): void {
        console.log("markProfileDelete");
        if (prof.marked_for_deletion === true) {
            prof.marked_for_deletion = false;
        } else {
            prof.marked_for_deletion = true;
        }
    }

    public specialPriceChanged(prof: IProfile): void {
        console.log("specialPriceChanged: ", prof);
        prof.acceptedPracticePrice = prof.specialPrice + prof.inhousePrice;

        prof.price_check = false;
    }

    public acceptedPetOwnerPriceChanged(prof: IProfile): void {
        console.log("acceptedPetOwnerPriceChanged: ", prof);
        prof.price_check = false;
    }

    public priceCheckClicked(prof: IProfile): void {
        console.log("priceCheckClicked:", prof);
        prof.price_check = !prof.price_check;
    }

    /**
     * Returns true if the specified enrollment is for a corporate account.
     * NOTE: If account settings for this account has as_independent set, then treat here as
     * non-corp as well.  But we don't have access to account settings as they were at the time, so
     * need to deduce from data that's present.
     */
    private isCorporateEnrollment(enrollInfo: IEnrollment): boolean {
        if (!enrollInfo.parent_sap_id) {
            return false;
        }
        // Only non-corporate accounts will have a recommended practice price.
        // So use that to figure out if this enrollment is for a practice that was treated as corporate
        // or individual.
        return enrollInfo.profiles.some((profile: IProfile): boolean => (
            profile.recommendedPracticePrice > 0
        ));
    }
}
