import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { IEnrollment } from "@shared/model/enrollment";
import { IProfile } from "@shared/model/profile";

@Component({
    selector: 'pcc-enroll-pet-care-heroes',
    templateUrl: './enroll-pet-care-heroes.component.html',
    styleUrls: ['./enroll-pet-care-heroes.component.scss']
})
export class EnrollPetCareHeroesComponent implements OnInit {
    @Input() public enrollInfo: IEnrollment;

    @Input() public sessionMode: SessionModeEnum;

    @Output() public enrollChanged = new EventEmitter<boolean>();

    public ngOnInit(): void {
    }

    public canChangePetCareHeroes(): boolean {
        if (this.sessionMode === SessionModeEnum.FRESH) {
            return true;
        }
        if (this.sessionMode === SessionModeEnum.EXPIRED) {
            return false;
        }

        return !this.enrollmentContainsPetCareHeroesProfiles();
    }

    // For an active enrollment, only allow the user to change PCH enrollment if they haven't previously enrolled.
    // The only way to find that out is to look for the "hidden" PCH profiles that will be present
    // in this enrollment already when an enrollment was submitted with PCH enrollment set to true.
    private enrollmentContainsPetCareHeroesProfiles(): boolean {
        return this.enrollInfo.profiles.some((profile: IProfile): boolean => profile.isPetCareHeroes);
    }

    public pchEnrolledChange() {
        // Triggering later to allow binding on required rule to update
        setTimeout((): void => {
            this.enrollChanged.emit(this.enrollInfo.petCareHeroesEnrolled);
        });
    }

}
