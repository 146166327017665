<div (click)="modalClicked($event)" class="spot-modal">
  <div class="spot-modal__header">
    <div class="spot-modal__titles" style="display:flex;flex-direction: column;">
      <h1 *ngIf="!canEditName" class="modal-title">{{profile.display_name}}</h1>
      <h1 *ngIf="canEditName" class="modal-title">
        <span class="input-edit-wrapper">
          <span (click)="editName(); nameInput.focus();" #nameSpan [ngClass]="{'no-display': editingName}" class="width-scalar-placeholder clickable editable-name">
            {{!displayName && ('build-profile.Profile_Name' | translate) || ''}}
            {{displayName}}
          </span>
        </span>
        <input
          #nameInput
          (input)="setDisplayName($event)"
          (keyup.enter)="editingName = false"
          [ngClass]="{'no-display' : !editingName}"
          [value]="displayName ? displayName : ''"
          class="width-scalar-placeholder"
          maxlength="40"
          [placeholder]="'build-profile.name_placeholder' | translate"
          type="text"
          />
        <button (click)="editName(); nameInput.focus();" #nameButton class="spot-button spot-button--secondary spot-button--icon-only button-edit">
		  <svg class="spot-icon spot-button__icon" aria-labelledby="title">
			<title>{{'build-profile.Edit' | translate}}</title>
			<use #nameButtonIcon xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#edit"></use>
		  </svg>
        </button>
      </h1>
      <span class="matched-testcode">
        <span class="matched-testcode-label">{{'build-profile.Test_code' | translate}}:</span>
        <span class="matched-testcode-value" style="position:relative;">
          <div [ngClass]="matching ? '' : 'transparent'" class="lds-ellipsis transition-transparency"
               style="position:absolute;top:-30px;left:-5px"><div></div><div></div><div></div><div></div></div>
          <span [ngClass]="matching ? 'transparent' : ''" class="transition-transparency">
            {{matchedProfile?.test_code}}
            <ng-container *ngIf="noMatch">
              {{'build-profile.no_matching_profile' | translate }}
            </ng-container>
          </span>
        </span>
      </span>
    </div>

    <button class="spot-modal__header-cancel-button" aria-label="Close" (click)="cancel()" >
      <svg class="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
        <title>{{ 'build-profile.Cancel' | translate }}</title>
        <use xlink:href="/assets/spot-library/dist/symbols/spot_symbols.svg#cancel"></use>
      </svg>
    </button>
  </div>
  <div class="spot-modal__content">
    <div class="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top"></div>
    <div class="spot-modal__content-wrapper">
      <div class="body-header">
        <div class="body-title">
          {{ modalTitle | translate}}
        </div>
        <div class="body-info">
          <span class="required-text">*required</span>
          <div class="deletePanel">
		    <pcc-confirm-action *ngIf="canReset" okKey="confirm_action.reset" (okClick)="resetClicked()"></pcc-confirm-action>
		    <pcc-confirm-action *ngIf="canDelete" okKey="confirm_action.delete" buttonTitle="confirm_action.delete" symbol="delete" (okClick)="deleteClicked()"></pcc-confirm-action>

          </div>
        </div>
      </div>

      <pcc-profile-category (testSelected)="testSelected(ctg, $event)" *ngFor="let ctg of categoryGroups"
                            [selectedTests]="selectedTests[ctg.developerName]"
                            [testCtg]="ctg"></pcc-profile-category>

      <p>

        <div style="position:relative;">
          <br/>
          {{message}}

        </div>
    </div>
    <div class="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom"></div>
  </div>
  <div class="spot-modal__footer">
    <button (click)="cancel()"
            class="spot-modal__footer-cancel-button"
            type="cancel">{{'build-profile.Cancel'| translate}}</button>

    <button (click)="save()" [disabled]="!matchedProfile || matching || saving || isProcessing"
            class="spot-button spot-button--primary spot-button--large "
            type="submit">{{saveButtonText| translate}}</button>
  </div>
</div>
