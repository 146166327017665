export class UtilService {
    public static stripLeadingZeros(sapId: string): string {
        if (sapId && sapId.replace) {
            return sapId.replace(/^0+/, "");
        }
        return sapId;
    }

    public static addLeadingZeros(sapId: string): string {
        if (sapId && sapId.padStart) {
            return sapId.padStart(10, "0");
        }
        return sapId;
    }

    /**
     * Take a list of strings and turn it into a single string, with each individual string having
     * quotes around it..
     *
     * For example:
     * joinStringList(['abc', 'def', 'ghi'])
     * Returns:
     * "'abc', 'def', 'ghi'"
     */
    public static joinStringList(strList: string[] | string): string {
        if (Array.isArray(strList)) {
            return `'${strList.join("','")}'`;
        } else if (strList) {
            return `'${strList}'`;
        }
        return "";
    }

    public static cleanName(name: string): string {
        if (!name) {
            return name;
        }
        let cleanName = name.replace(/([a-z\xE0-\xFF])([A-Z\xC0\xDF])/g, "$1 $2");
        // convert to upper case.
        cleanName = cleanName.toUpperCase();
        // Replace all non-alphanumeric with space.
        cleanName = cleanName.replace(/[^a-z0-9\xE0-\xFF]/gi, " ");
        cleanName = cleanName.trim();
        cleanName = cleanName.replace(/ /g, "_");
        return cleanName;
    }

    public static trimToNull(str?: string): string | null {
        let retVal: string | null = str || null;
        if (str && str.trim) {
            retVal = str.trim();
        }
        if (retVal === "" || retVal === "null") {
            retVal = null;
        }
        return retVal;
    }

    // Remove when javascript's proposal-array-grouping is implemented
    public static groupBy<T>(list: T[], fn: (item: T) => string): Record<string, T[]> {
        return list.reduce<Record<string, T[]>>((group, item: T): Record<string, T[]> => {
            const key = fn(item);
            group[key] = group[key] ?? [];
            group[key].push(item);
            return group;
        }, {});
    }

    public static compareDatesIgnoreTime(date1: string | Date, date2: string | Date): number {
        // Convert date strings to Date objects if they are strings
        const d1 = date1 instanceof Date ? date1 : new Date(date1);
        const d2 = date2 instanceof Date ? date2 : new Date(date2);

        // Set the time portion of the dates to midnight
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);

        // Compare the timestamps of the modified dates
        return d1.getTime() - d2.getTime();
    }

    public static compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public static compareBool(a: boolean, b: boolean): number {
        if (a === b) {
            return 0;
        }
        if (a) {
            return -1;
        }
        return 1;
    }

    /**
     * Checks if two arrays of strings contain the same items, regardless of order.
     *
     * @param array1 - The first array of strings.
     * @param array2 - The second array of strings.
     * @returns A boolean indicating if the arrays contain the same items.
     */
    public static compareArrays(array1: string[], array2: string[]): boolean {
        // Check if both arrays have the same length
        if (array1.length !== array2.length) {
            return false;
        }

        // Create a set from each array for faster lookup
        const set1 = new Set(array1);
        const set2 = new Set(array2);

        // Compare the sets
        return set1.size === set2.size && [...set1].every(item => set2.has(item));
    }
}
