import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom, Observable, BehaviorSubject, of } from "rxjs";
import { tap, map, shareReplay, share } from "rxjs/operators";

import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { IAccount } from "@shared/model/account";
import { IAPIResponseData } from "@shared/model/service/service";
import { ISessionCount } from "@shared/model/account-response";
import { IAccountSettings, AccountSettings } from "../../../shared/model/account-settings";
import { ISystemSettings } from "../../../shared/model/system-settings";
import { PCCSession } from "../../../shared/model/pcc-session";
import { EnrollUtils, EnrollmentStatus } from "../../../shared/model/enrollment";
import { IEnrollmentsRequest, IEnrollmentsResponse } from "@shared/model/service/enrollments-service";
import { IEnrollResponse, ICleanSessionsRequest } from "@shared/model/service/enroll-service";
import { IProfileMatchRequest, IProfileMatchResponse } from "@shared/model/service/profile-match-service";
import { IEnrollment } from "@shared/model/enrollment";
import { TestMixService } from "./testmix.service";
import { PCCPriceService } from "./price.service";
import { AppService } from "./app.service";
import { ApiService } from "./api.service";
import { IProfile, ProfileUtils } from "../../../shared/model/profile";
import { IProfileResponse } from "@shared/model/service/profile-service";

import { PCCTranslateService } from "./translate.service";

import { ManagedCountries } from "@shared/model/country";

import { PCCClientError } from "../shared/model/pcc-client-error";

import { PCCAlertService } from "./alert.service";

import _ from "lodash";

import AwaitLock from "await-lock";
const LOCK = new AwaitLock();

/**
 * PCC Session Service
 */

const enum API {
    checkEnrollmentStatus = "/api/enroll/status",
    getEnrollment = "/api/admin/enrollment/",
    sendEmail = "/api/pdf/email",
    saveSession = "/api/session",
    submitEnrollment = "/api/enroll",
    getEnrollments = "/api/admin/enrollments",
    findMatchingProfile = "/api/profiles",
    cleanupSessions = "/api/session/clean"
}

@Injectable({
    providedIn: "root"
})
export class PCCSessionService {

    private session: PCCSession;

    private currentSession: BehaviorSubject<PCCSession>;

    public constructor(
        private appService: AppService,
        private http: HttpClient,
        private apiService: ApiService,
        private testMixService: TestMixService,
        public alertService: PCCAlertService,
        private translateService: PCCTranslateService,
        private priceService: PCCPriceService
    ) {
        console.log("PCC Session Service constructor");
        this.session = new PCCSession();
        this.currentSession = new BehaviorSubject<PCCSession>(this.session);

        this.initLanguage();
    }

    private initLanguage(): void {
        console.log("Calling setLocale: ", this.translateService.locale);

        this.translateService.localeSubject.subscribe((locale: string): void => {
            console.log("pcc-session.service subscribe to localeSubject changed: ", locale);
            this.setLocale(locale);
        });
    }

    public getCurrentSession(): Observable<PCCSession> {
        return this.currentSession.asObservable();
    }

    public clearSession(): void {
        console.log("clearSession");
        this.setSession(new PCCSession());
    }

    private setAccountInfo(session: PCCSession, acctInfo: IAccount): void {
        if (acctInfo) {
            session.isCorporate = AccountSettings.isCorporate(session.accountSettings)
            session.countryCd = acctInfo.country_cd;
        }
    }

    public async createSession(acctInfo: IAccount, acctSettings: IAccountSettings, locale?: string, enrollInfo?: IEnrollment, persist?: boolean, sessionMode?: SessionModeEnum): Promise<PCCSession> {
        console.log("startSession: ", acctInfo, acctSettings);
        const session = new PCCSession();
        this.session = session;
        session.enrollInfo = enrollInfo;

        if (locale) {
            this.setLocale(locale);
        } else {
            locale = this.translateService.getDefaultLocale(acctSettings.supportedLocales, acctSettings.defaultLocale);
            this.setLocale(locale);
        }
        this.testMixService.testmixObs = null;

        session.accountSettings = acctSettings;
        session.activeSettings = acctSettings;

        session.accountInfo = acctInfo;
        session.systemSettings = await this.appService.getSystemSettingsCached();
        session.countryCd = acctInfo?.country_cd ?? acctSettings?.countryCd;

        this.setAccountInfo(session, acctInfo);

        if (acctSettings?.flags.use_test_mix) {
            this.testMixService.getTestMix(session?.accountInfo);
        }

        if (persist) {
            const savedSession = await this.saveSession();
            console.log("savedSession = ", savedSession);

            if (savedSession && savedSession.enrollInfo) {
                session.enrollInfo.enrollment_id = savedSession.enrollInfo.enrollment_id;
            }
        }

        session.sessionMode = sessionMode;

        // If an enrollment has already been submitted, user can't delete invalid profiles,
        // so it's not worth validating here.
        if (sessionMode !== SessionModeEnum.EXPIRED) {
            this.validateEnrollment(session);
        }

        console.log("Publishing currentSession...", session);
        this.currentSession.next(session);

        return session;
    }

    public async startAdminSession(acctInfo: IAccount,
        acctSettings: IAccountSettings,
        sysSettings: ISystemSettings,
        sessionCount: ISessionCount): Promise<PCCSession> {
        console.log("startAdminSession: ", acctInfo, acctSettings);
        const session = new PCCSession();
        this.session = session;

        session.accountSettings = acctSettings;
        session.accountInfo = acctInfo;
        session.systemSettings = sysSettings;
        session.sessionCount = sessionCount;

        session.countryCd = acctInfo?.country_cd ?? acctSettings?.countryCd;

        return session;
    }

    public setSession(sess: PCCSession): void {
        console.log("setNewSession: ", sess);
        this.session = sess;
        this.currentSession.next(sess);
    }

    private setLocale(locale: string): void {
        console.log(`setLocale: ${locale}`);
        this.session.locale = locale;

        this.translateService.get("currency-mask").subscribe((v): void => {
            this.session.currencyMaskOptions = v;
        });
    }

    public checkEnrollmentStatus({ id }: { id: number }): Observable<IAPIResponseData<EnrollmentStatus>> {
        return this.http.get<IAPIResponseData<EnrollmentStatus>>(
            `${API.checkEnrollmentStatus}`,
            {
                params: {
                    id: `${id}`
                }
            }
        ).pipe(
            share(),
            shareReplay()
        );
    }

    public sendEmail({ id }: { id: number } = {
        id: this.session && this.session.enrollInfo && this.session.enrollInfo.enrollment_id
    }): Observable<unknown> {
        if (this.session.submitting) {
            return of({
                success: false
            });
        }
        const result = this.http.post(
            `${API.sendEmail}`,
            {
                id,
                trainingMode: this.appService.isTrainingMode()
            }
        ).pipe(
            share(),
            shareReplay()
        );
        result.subscribe((): void => {
            this.session.submitting = false;
        }, (): void => {
            this.session.submitting = false;
        });
        return result;
    }

    public async saveEnrollment(enrollInfo: IEnrollment, forAdmin = false): Promise<IEnrollResponse> {
        console.log("saveEnrollment: ", enrollInfo);

        await LOCK.acquireAsync();
        console.log("========== ENROLLMENT Lock acquired! ==========");
        try {
            // IMPORTANT: Do not return a promise from here because the finally clause
            // may run before the promise settles, and the catch clause will not run if
            // the promise is rejected

            return await this.saveEnrollmentInner(enrollInfo, forAdmin);
        } finally {
            console.log("========== Releasing ENROLLMENT lock ==========");
            LOCK.release();
        }
    }

    private async saveEnrollmentInner(enrollInfo: IEnrollment, forAdmin: boolean): Promise<IEnrollResponse> {
        console.log("saveEnrollmentInner");
        try {
            const saveObservable = this.http.post<IEnrollResponse>(API.saveSession, enrollInfo,
                {
                    params: {
                        admin: forAdmin
                    }
                })
                .pipe(
                    map((saveResp: IEnrollResponse): IEnrollResponse => {
                        console.log("saveEnrollment.saveResp: ", saveResp);

                        if (saveResp && saveResp.success && saveResp.enrollInfo) {
                            EnrollUtils.updateEnrollment(enrollInfo, saveResp.enrollInfo);
                        }
                        return saveResp;
                    }),
                    tap((tapResp): void => {
                        console.log("tap: ", tapResp);
                    }, (err): Observable<IEnrollResponse> => {
                        console.error("Error calling saveEnrollment: ", err);
                        return of({
                            success: false, error: err
                        } as IEnrollResponse);
                    })
                )
                .toPromise();

            return await saveObservable;
        } catch (err) {
            console.error("Error here: ", err);
            return null;
        }
    }

    // TODO: Lock down while saveEnrollment is taking place, and vice-versa
    public async saveProfile(profile: IProfile): Promise<IProfileResponse> {
        console.log("saveProfile: ", profile);

        await LOCK.acquireAsync();
        console.log("========== Lock acquired! ==========");
        try {
            // IMPORTANT: Do not return a promise from here because the finally clause
            // may run before the promise settles, and the catch clause will not run if
            // the promise is rejected
            console.log("Inside try block...");

            const saveResp = await this.apiService.saveProfile(profile);
            if (saveResp && saveResp.success && saveResp.profile) {
                ProfileUtils.updateProfile(profile, saveResp.profile);
            }
            return saveResp;

        } finally {
            console.log("========== Releasing lock ==========");
            LOCK.release();
        }

    }

    /**
     * Delete a persisted profile.
     * Removes from enrollment.
     */
    public async deleteProfile(profile: IProfile, enrollInfo: IEnrollment, isReset: boolean): Promise<boolean> {
        console.log("deleteProfile: ", profile);
        try {
            const success = this.removeProfile(enrollInfo, profile);
            if (success) {
                const saveResp = await this.saveSession();
                console.log("deleteProfile.saveResp = ", saveResp);
                if (saveResp.success) {
                    const successMessageKey = isReset ? "slot.profile_reset" : "slot.profile_deleted";
                    this.alertService.showToast(this.translateService.instant(successMessageKey), "");
                } else {
                    const errorMessageKey = isReset ? "slot.profile_reset_failed" : "slot.profile_delete_failed";
                    this.alertService.showError(this.translateService.instant("alerts.error"),
                        this.translateService.instant(errorMessageKey));
                }
                return saveResp.success;
            }
            return false;
        } catch (err) {
            if (err instanceof PCCClientError) {
                throw err;
            }
            throw new PCCClientError("PROFILE_DELETE", "Error thrown deleting profile", err);
        }
    }

    // Saves enrollment info to database for save-and-retrieve.
    // Note that this call only posts the enrollment object itself, not any profiles attached to it.
    public saveSession(): Promise<IEnrollResponse> {
        console.log("saveSession", this.session);

        const enrollInfo = this.session.enrollInfo;

        enrollInfo.psvUser = this.session.accountInfo.psvUser;
        if (ManagedCountries.AU === enrollInfo.practiceCountryCd) {
            enrollInfo.fsrUser = this.session.accountInfo.iftsUser;
        } else {
            enrollInfo.fsrUser = this.session.accountInfo.fsrUser;
        }

        return this.saveEnrollment(enrollInfo);

    }

    // Saves final enrollment info to database.
    // Generates PDF
    // Emails enrollment info.
    // Returns success information as well as generated PDF doc (?)
    public submitEnrollment(session: PCCSession = this.session): Observable<IEnrollResponse> {
        console.log("submitEnrollment");
        if (session.submitting || !session.enrollInfo) {
            return of({
                success: false
            });
        }
        session.submitting = true;
        if (session.sessionMode !== SessionModeEnum.EXPIRED) {
            session.enrollInfo.enrollmentDate = new Date();
        }
        session.enrollInfo.status = "ENROLLED";

        const result = this.http.post<IEnrollResponse>(API.submitEnrollment, {
            ...this.session.enrollInfo,
            locale: this.session.locale,
            trainingMode: this.appService.isTrainingMode()
        })
            .pipe(
                share(),
                shareReplay()
            );
        result.subscribe((): void => {
            session.submitting = false;
        }, (): void => {
            session.submitting = false;
        });
        return result;
    }

    // Retrieves a list of enrollments based upon the supplied criteria.
    public getEnrollments(enrollReq?: IEnrollmentsRequest): Observable<IEnrollmentsResponse> {
        console.log("getEnrollments: ", enrollReq);
        if (!enrollReq) {
            enrollReq = {
            };
        }

        console.log("Calling: ", (API.getEnrollments));

        return this.http.post<IEnrollmentsResponse>(API.getEnrollments, enrollReq)
            .pipe(
                share(),
                shareReplay()
            );

    }

    public getSession(): PCCSession {
        return this.session;
    }

    public async findMatchingProfile(profileRequest: IProfileMatchRequest): Promise<IProfileMatchResponse> {
        return await firstValueFrom(
            this.http.post<IProfileMatchResponse>(API.findMatchingProfile, profileRequest)
        );
    }

    // Retrieves the specified enrollment along with associated data
    public getEnrollment(id: string | number): Observable<IEnrollResponse> {
        return this.http.get<IEnrollResponse>(API.getEnrollment + id);
    }

    /**
     * When a user gets prompted if they want to re-use an old session, we need to
     * clean up all the old sessions.  If a user chooses to not re-use the old
     * session, we should clean it up as well.
     * So this call does the equivalent of deleting all non-submitted rows in the
     * enrollment table for this account.  If they re-use a session, delete all
     * but that one.
     */
    public async cleanupSessions(cleanRequest: ICleanSessionsRequest): Promise<IEnrollResponse> {
        console.log("cleanupSessions: ", cleanRequest);

        return await firstValueFrom(
            this.http.post<IEnrollResponse>(API.cleanupSessions, cleanRequest)
        );
    }

    /**
     * Remove profile from Enrollment.
     */
    public removeProfile(enrollInfo: IEnrollment, profile: IProfile): boolean {
        if (profile) {
            const foundProfile = EnrollUtils.findProfile(profile, enrollInfo);
            if (foundProfile) {
                const removed = _.remove(enrollInfo.profiles, foundProfile);
                if (profile.extraProfile) {
                    this.removeProfile(enrollInfo, foundProfile.extraProfile);
                }
                if (removed) {
                    return true;
                }
            }

            console.log("Profile not in enrollment, couldn't remove");
        }
        return false;
    }

    // Testing if the template each profile was created from still exists and still supports the selections that went into building the profile.
    private validateEnrollment(session: PCCSession): void {
        const profiles = session.enrollInfo?.profiles || [];
        const templates = session.accountSettings?.profileTemplates || [];

        profiles.forEach((profile: IProfile): void => {

            // Check to make sure that the template used to build each profile is still active and present
            // in the list of profile templates for this account/region/accountSettings.
            if (!ProfileUtils.isTemplateAvailable(profile, templates)) {
                console.warn("Template not available: ", profile, templates);
                profile.error = {
                    message: this.translateService.instant("profile.TEMPLATE_DELETED")
                };
                profile.invalid = true;
            } else if (!ProfileUtils.isProfileSupported(profile, profile.profileTemplate)) {
                console.warn("template was changed: ", profile);
                profile.error = {
                    message: this.translateService.instant("profile.TEMPLATE_CHANGED_AFTER_PROFILE")
                };
                profile.invalid = true;
            }
        });
    }

    /**
     * Re-run all pricing logic for all profiles contained in enrollment.
     * We could do this just by calling runPricing 1-many times, once per profile.
     * But attempting to optimize this so we don't plaster SAP with requests.
     * Instead, going to assemble all price-able materials, make a single call,
     * and then split them back out...
     */
    public async reloadPricing(session: PCCSession): Promise<boolean> {
        console.log("reloadPricing", session);

        const pricesFound = await this.priceService.reloadPricing(session);

        // Call setSession with the same session to make sure profiles page
        // gets updated and Next button activates after pricing is complete.
        this.setSession(this.getSession());

        return pricesFound;
    }
}
