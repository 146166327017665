import { IProfileTemplate, ProfileTemplate, TemplateType, TEMPLATE_TYPES } from "./profile-template";
import { IProduct } from "./product";
import { IProfileItem, IReplaceRule, IModalityTests, ItemUtils } from "./profile-item";
import { IProfileCategory } from "./profile-category";
import { ISpecies } from "./species";
import { IUser } from "./user";
import { ICategory } from "./category";
import { IPricedItem } from "./price";
import { IModality, ModalityEnum } from "./modality";
import { LocalizedContent } from "./language";

import _ from "lodash";

import moment from "moment";

const PROFILE_ITEM_CODES = {
    CA_IDEXX_ANYWHERE_CODE: "SEDUA",
    CA_URINALYSIS_CODE: "2701",
    IDEXX_ANYWHERE_CODE: "SEDUA",
    REFLEX_UPC_CODE: "SEDUPC",
    REFLAB_URINALYSIS: "910",
    REFLAB_REFLEX_UPC: "950"
};

export interface IProfileError {
    message?: string;
    hasOrderBlock?: boolean;
}

export interface ICategoryTests {
    category: string;
    tests: IProfileItem[];
}

export interface IModalityCategories {
    modality: string;
    categories?: ICategoryTests[];
}

interface IReviewConfigurations {
    profile_test_code: string;
    modalityTests: IModalityTests[]
}

export interface IProfile extends IProfileTemplate, IPricedItem {
    clientId?: string;

    // @database enrollment_profile_id
    enrollment_profile_id?: number;

    // @database enrollment_id
    enrollment_id?: number;

    display_name?: string;

    // Indicates if profile is added to enrollment or just to session.
    selected?: boolean;

    // Indicates if profile is a PCH code
    isPetCareHeroes?: boolean;

    profileTemplate?: IProfileTemplate;

    // @database template_profile_id
    template_profile_id: number;

    // Result of user selecting one item per category row.  If None is allowed
    // and selected, nothing will show up in this list for that category.
    profileItems?: IProfileItem[];

    // The final list of product ids used to match against a profile in MDOS
    // after applying any rules...
    products?: IProduct[];

    // Matched profile id from taking the list of individual test component ids and
    // finding the matching profile that includes all of them (and nothing else)
    // @database matched_product
    profile_test_code?: string;

    matchedProfile?: IProduct;

    // A profile can only be associated with a single species.
    // @database species_id
    species: ISpecies;

    // Pulled from mdos/order simulate (netPrice)
    // @database list_price
    listPrice?: number;

    // Customer-specific pricing taking from sap order simulate (totalPrice)
    // @database customer_price
    customerPrice?: number;

    // For AU only, data from uploaded spreadsheet.
    // @database reference_price
    referencePrice?: number;

    // Result of pricing algorithm for matched REF_LAB profile.
    // For CA: listPrice * discount
    // For US: Calling pricing lambda.
    // For AU: refPrice
    // @database recommended_special_price
    recommendedPracticePrice?: number;

    // Editable by user
    // Represents the price to use for REF_LAB portion of the profile.
    // Defaults to listPrice for US/CA
    // Defaults to refPrice for AU
    // Not used for corporate
    // @database special_price
    specialPrice?: number;

    // @database inhouse_price
    inhousePrice?: number;

    // Defaults to specialPrice + inhousePrice
    // @database accepted_practice_price
    acceptedPracticePrice?: number;

    // Editable by user
    // Defaults to recommendedPetOwnerPrice
    // @database pet_owner_price
    acceptedPetOwnerPrice?: number;

    // Result of running pricing algorithm for corp.
    // @database recommended_pet_owner_price
    recommendedPetOwnerPrice?: number;

    /**
     * US/CA floor price
     * @database floor_price
     */
    floorPrice?: number;

    completed?: boolean;

    isExtra?: boolean;
    // When an extra profile is added to the enrollment, keep a reference here to
    // the source profile.  If the source profile is edited, need to delete the extra profile.
    srcProfile?: IProfile;
    extraProfile?: IProfile;

    modality: IModality;

    // @database goal_quantity
    goalQuantity?: number;

    // @database goal_pet_owner_charge
    goalPetOwnerCharge?: number;

    // @database goal_total_profit
    goalTotalProfit?: number;

    error?: IProfileError;

    // Indicates when a profile is no longer supported by it's template due to admin changes.
    invalid?: boolean;

    loading?: boolean;

    // Set to true to indicate to downstream processes that the price has been
    // adjusted on this profile.
    // Will be reset to true after that by the downstream processes.
    price_check?: boolean;

    marked_for_deletion: boolean;

    version: number;

    created_on?: Date;
    createdBy?: IUser;

    updated_on?: Date;
    updatedBy?: IUser;

}

/**
 * Interface representing a built profile after the user goes through the
 * process of selecting a profile template and selecting from the list of
 * available tests.
 */
export class Profile implements IProfile, IPricedItem {

    public clientId?: string;

    // Database id for storing profile
    public enrollment_profile_id?: number;

    // Database id for persisted enrollment
    public enrollment_id?: number;

    // Indicates if profile is added to enrollment or just to session.
    public selected?: boolean;

    // Indicates if profile is a PCH code
    public isPetCareHeroes?: boolean;

    public profileTemplate?: IProfileTemplate;

    public template_profile_id: number;

    // Result of user selecting one item per category row.  If None is allowed
    // and selected, nothing will show up in this list for that category.
    public profileItems: IProfileItem[] = [];

    // Matched profile id from taking the list of individual test component ids and
    // finding the matching profile that includes all of them (and nothing else)
    public profile_test_code?: string;

    public matchedProfile?: IProduct;

    // The final list of product ids used to match against a profile.
    public products?: IProduct[];

    // @database list_price
    public listPrice?: number;

    // @database customer_price
    public customerPrice?: number;

    // @database reference_price
    public referencePrice?: number;

    // @database recommended_special_price
    public recommendedPracticePrice?: number;

    // @database special_price
    public specialPrice?: number;

    // @database inhouse_price
    public inhousePrice?: number = 0;

    // @database accepted_practice_price
    public acceptedPracticePrice?: number;

    // Result of running the corporate-defined pricing algorithm against the customer price and the
    // full lists of selected tests
    // @database recommended_pet_owner_price
    public recommendedPetOwnerPrice?: number;

    // @database pet_owner_price
    public acceptedPetOwnerPrice?: number;

    // US/CA floor price
    public floorPrice?: number;

    public completed?: boolean = false;

    // The account settings record id this template corresponds to.
    // Will be null in the case of a "system profile template".
    public account_settings_id: number;

    // Display name to be shown in the tile and in the "build profile" page.
    public display_name?: string;

    // A profile can only be associated with a single species.
    public species: ISpecies;

    // The order to display this profile template in the tiles (todo)
    public display_order?: number = 0;

    // In the case of in-house urinalysis, we need to display only the specific matched profiles on the Profiles page.
    // But on the final enrollment page (and the generated output pdf), we need to show both in-house and other profiles.
    public isExtra?: boolean;

    public modality: IModality;

    // When an extra profile is added to the enrollment, keep a reference here to
    // the source profile.  If the source profile is edited, need to delete the extra profile.
    public srcProfile?: IProfile;

    public extraProfile?: IProfile;

    public goalTotalProfit?: number;

    public goalQuantity?: number;

    public goalPetOwnerCharge?: number;

    public loading?: boolean;

    // Set to false to indicate to downstream processes that the price has been
    // adjusted on this profile and needs to be sent to client reg (again).
    // Will be reset to true after that by the downstream processes.
    public price_check?: boolean;

    public marked_for_deletion = false;

    public version = 1;

    public templateType: TemplateType;

    // Used to contain localized display text for this object
    public localizedKeys: LocalizedContent;

    public created_on?: Date;

    public createdBy?: IUser;

    public updated_on?: Date;

    public updatedBy?: IUser;

    private pError?: IProfileError;

    public get error(): IProfileError {
        return this.pError;
    }

    public set error(err: IProfileError) {
        console.log("profile set error: ", err);
        this.pError = err;
    }

    /**
     * Constructor takes profile template, which is the saved version from admin.
     * Turns it into a populate-able profile to be saved forward to the database as
     * a populated profile matching a known mdos profile id...
     */
    public constructor(tpl?: IProfileTemplate) {
        this.profileTemplate = tpl;

        if (tpl) {
            ProfileUtils.initFromTemplate(this, tpl);
        }
    }

    // TODO constructor should support initialization from simple json representation
    // interface should go away
    // properties should go away where possible
    public static from(fromProfile: IProfile | Profile, toProfile?: IProfile | Profile): Profile {
        const result = toProfile || new Profile();
        for (const p in fromProfile) {
            // @ts-ignore should ideally have one profile interface/class to avoid this
            result[p] = fromProfile[p];
        }
        return <Profile>result;
    }

    public isSelected(test: IProfileItem): boolean {
        if (!test) {
            return false;
        }
        const foundTest = this.profileItems.find((st: IProfileItem): boolean =>
            st.profile_item_id === test.profile_item_id
        );
        return Boolean(foundTest);

    }

    public reset(): void {
        this.clearTests();
        ProfileUtils.clearPrices(this);
        delete this.enrollment_profile_id;
        delete this.profile_test_code;
        delete this.matchedProfile;
        delete this.completed;
        this.profileItems = [];
    }

    public clearTests(): void {
        console.log("clearTests");
        this.profileItems = [];
    }

    public static fromTemplate(template: IProfileTemplate, enrollId?: number): Profile {
        const newProfile = new Profile(template);
        newProfile.enrollment_profile_id = enrollId;
        return newProfile;
    }
}

export const ProfileUtils = {

    /**
     * Loop through the selected profile items and translate them into an array of product test codes
     * to be used to match against a reference-labs profile.
     * Uses test_type and any possible replacements logic to bring these together.
     * Test type:
     * STANDARD -> Just dump any product codes the profile item contains into the list.
     * ADD_ON -> Just dump any product codes the profile item contains into the list.  (The UI will enforce any standard test selection.)
     * OVERRIDE -> Instead of applying both the STANDARD and ADD_ON product codes to the list, will
     *             ignore any other profile item _in this category_ and only use the product ids supplied in this profile item.
     * Replacement Rules -> This can get ugly.  If any replacement rules are defined for a selected profile item:
     *     First apply all of the above rules to the profile, but then loop back and replace any
     *     single product id with its replacement value as defined in the replacement rules.

     */
    getProductsForMatch(selectedProfileItems: IProfileItem[]): IProduct[] {
        let products: IProduct[] = [];

        // Only Reference Labs profile items are used for profile matching.  Filter out any others.
        const profileItems = selectedProfileItems.filter(this.isRefLabProfileItem);

        let replacementRules: IReplaceRule[] = [];

        // Best way to apply rules is to loop through selected profile items by category.
        // This will support all of STANDARD, ADD_ON, and OVERRIDE types in an efficient manner.
        const testMap = _.groupBy(profileItems, (profileItem: IProfileItem): string => profileItem.category.developerName);

        for (const ctgName in testMap) {
            const items: IProfileItem[] = <IProfileItem[]>testMap[ctgName]; // Should only contain 1-2 profile items at most

            const { products: ctgProducts, replacementRules: ctgReplacementRules } = this.getProductsForMatchByCategory(items);

            products = _.concat(products, ctgProducts);
            replacementRules = replacementRules.concat(ctgReplacementRules);
        }

        replacementRules.forEach((replaceRule: IReplaceRule): void => {
            const fromProd = replaceRule.from_product;
            const toProd = replaceRule.to_product;
            const removed = _.remove(products, (p: IProduct): boolean => (
                p.product_id === fromProd.product_id
            ));
            if (removed && removed.length > 0) {
                console.log("Replacing ", removed, " with ", toProd);
                if (toProd) {
                    products.push(toProd);
                }
            }
        });

        return products.filter((product: IProduct): boolean => (product.test_code !== undefined && product.test_code !== null));
    },

    // Given a list of profile items for a specific category, return the products used for matching and any replacement rules
    getProductsForMatchByCategory(profileItems: IProfileItem[]): { products: IProduct[], replacementRules: IReplaceRule[] } {
        let ctgProducts: IProduct[] = [];
        let replacementRules: IReplaceRule[];
        profileItems.forEach((profileItem: IProfileItem): void => {
            if (ItemUtils.isStandard(profileItem)
                || ItemUtils.isAddOn(profileItem)) {
                ctgProducts = _.concat(ctgProducts, profileItem.products);
            } else if (ItemUtils.isOverride(profileItem)) {
                ctgProducts = profileItem.products;
            } else if (ItemUtils.isStatic(profileItem)) {
                ctgProducts = [];
            } else {
                console.log("Unknown test type: ", profileItem.test_type, profileItem);
            }

            if (profileItem.replacementRules) {
                replacementRules = profileItem.replacementRules;
            }
        });

        return {
            products: ctgProducts, replacementRules
        };
    },

    isRefLabProfileItem(profileItem: IProfileItem): boolean {
        if (!profileItem) {
            return false;
        }
        return (
            ModalityEnum.REF_LAB.equals(profileItem.modality.developer_name) ||
            ModalityEnum.REF_LAB_IHD.equals(profileItem.modality.developer_name)
        );
    },

    getProductCodesForMatch(profileItems: IProfileItem[]): string[] {
        const products = this.getProductsForMatch(profileItems);
        return _.map(products, "test_code");
    },

    forReview(profiles: IProfile[]): IProfile[] {
        return profiles;
    },

    // Filter out profiles added in for IDEXX Anywhere scenario when we only want user-selected profiles.
    forEnroll(profiles: IProfile[]): IProfile[] {
        if (!profiles) {
            return [];
        }
        return profiles.filter((profile: IProfile): boolean => (!profile.isExtra && profile.selected && !profile.isPetCareHeroes));
    },

    filterSelectedProfiles(profiles: IProfile[]): IProfile[] {
        if (!profiles) {
            return [];
        }
        return profiles.filter((profile: IProfile): boolean =>
            profile.completed && profile.selected && !profile.isExtra && !profile.isPetCareHeroes
        );
    },

    map(profile: IProfile | Profile): Profile {
        return Profile.from(profile);
    },

    /**
     * Returns true if profile has been populated, matched, priced-out, and persisted.
     */
    isValid(profile: IProfile): boolean {
        return profile
            && profile.completed
            && (profile.acceptedPracticePrice || profile.acceptedPetOwnerPrice)
            && typeof profile.enrollment_profile_id === "number";
    },

    isIDEXXAnywhere(profileItem: IProfileItem): boolean {
        const testCode = ItemUtils.getSingleProductTestCode(profileItem);
        if (!testCode) {
            return false;
        }
        return testCode === PROFILE_ITEM_CODES.CA_IDEXX_ANYWHERE_CODE
            || testCode === PROFILE_ITEM_CODES.IDEXX_ANYWHERE_CODE;
    },

    isIDEXXAnywhereUPC(profileItem: IProfileItem): boolean {
        if (profileItem.products && profileItem.products.length > 0) {
            return profileItem.products.some((product: IProduct): boolean =>
                product.test_code === PROFILE_ITEM_CODES.REFLEX_UPC_CODE
            );
        }
        return false;
    },

    isUrinalysis(profileItem: IProfileItem): boolean {
        const testCode = ItemUtils.getSingleProductTestCode(profileItem);
        if (!testCode) {
            return false;
        }
        return testCode === PROFILE_ITEM_CODES.CA_URINALYSIS_CODE
            || testCode === PROFILE_ITEM_CODES.REFLAB_URINALYSIS;
    },

    // Urinalysis with Reflex UPC contains 910 and 950
    isUrinalysisWithReflexUPC(profileItem: IProfileItem): boolean {
        return (profileItem.products
            && profileItem.products.length === 2
            && profileItem.products.some((product: IProduct): boolean => product.test_code === PROFILE_ITEM_CODES.REFLAB_URINALYSIS)
            && profileItem.products.some((product: IProduct): boolean => product.test_code === PROFILE_ITEM_CODES.REFLAB_REFLEX_UPC));
    },

    // Returns true if any of the profile items contain only SEDUA
    containsIDEXXAnywhereUrinalysis(profileItems: IProfileItem[]): boolean {
        return profileItems.some((profileItem: IProfileItem): boolean => ProfileUtils.isIDEXXAnywhere(profileItem));
    },

    // Returns true if any of the profile items contain SEDUPC
    containsIDEXXAnywhereUPC(profileItems: IProfileItem[]): boolean {
        return profileItems.some((profileItem: IProfileItem): boolean => ProfileUtils.isIDEXXAnywhereUPC(profileItem));
    },

    getIDEXXAnywhereUrinalysis(profileItems: IProfileItem[]): IProfileItem | null {
        return profileItems.find((profileItem: IProfileItem): boolean => ProfileUtils.isIDEXXAnywhere(profileItem));
    },

    getIDEXXAnywhereUPC(profileItems: IProfileItem[]): IProfileItem | null {
        return profileItems.find((profileItem: IProfileItem): boolean => ProfileUtils.isIDEXXAnywhereUPC(profileItem));
    },

    containsLabUrinalysis(profileItems: IProfileItem[]): boolean {
        return profileItems.some((profileItem: IProfileItem): boolean => ProfileUtils.isUrinalysis(profileItem));
    },

    // Return any selected test(s) for the specified category (developer_name)
    // If no category specified, just return full list of selected tests.
    getSelectedTests(prof: IProfile, ctg?: ICategory): IProfileItem[] {
        if (!ctg) {
            return prof.profileItems;
        }
        const result: IProfileItem[] = [];
        if (prof.profileItems) {
            prof.profileItems.forEach((st: IProfileItem): void => {
                if (st.category.category_id === ctg.category_id) {
                    result.push(st);
                }
            });
        }
        if (result.length === 0) {
            return null;
        }
        return result;
    },

    /**
     * Clear any pricing data in advance of calling to get updated pricing
     * information for a profile after matching to a new LIMS profile or
     * after retrieving old session.
     */
    clearPricing(profile: IProfile): void {
        console.log("clearPricing: ", profile);

        if (!profile) {
            return;
        }
        delete profile.listPrice;
        delete profile.customerPrice;
        delete profile.referencePrice;
        delete profile.recommendedPetOwnerPrice;
        delete profile.recommendedPracticePrice;
        delete profile.acceptedPracticePrice;
        delete profile.acceptedPetOwnerPrice;
        delete profile.specialPrice;
        delete profile.inhousePrice;
        delete profile.floorPrice;
    },

    /**
     * Repopulate a profile from saved data.
     */
    retrieveProfile(oldProfile: IProfile, isPartial: boolean = true, isComplete: boolean = false): IProfile {
        console.log("retrieveProfile: ", oldProfile);
        const newProfile = Profile.from(oldProfile);

        if (!isPartial && !isComplete) {
            newProfile.version = 1; // Reset the version
        }

        const today = moment();
        const createdOn = moment(newProfile.created_on);
        if (isPartial) {
            if ((today.diff(createdOn, "days") < 90)
                || createdOn.year() === today.year()) {
                // console.log("Keeping special price...");
            } else {
                console.warn("Date more than 90 days old and different year, wiping out special price!");
                newProfile.specialPrice = newProfile.recommendedPracticePrice ?? newProfile.recommendedPetOwnerPrice;
            }
        }

        const tpl = newProfile.profileTemplate;
        if (tpl) {
            // Normally, enrollments are built up by the user selecting
            // items from the template.  When re-loading a session,
            // need to make sure the selected profile-items are the same
            // instance as that in the template.  Otherwise, we run into
            // trouble in build-profile.  Ideally, we could re-work that
            // so it does identity comparison instead of instance
            // comparison, but it's not out of the box for angular, so
            // taking this path instead.
            newProfile.profileItems = newProfile.profileItems.map((profileItem: IProfileItem): IProfileItem => {
                const foundProfileItem = tpl.categories
                    .flatMap((ctg: IProfileCategory): IProfileItem[] => ctg.profileItems)
                    .find((tp: IProfileItem): boolean => tp.profile_item_id === profileItem.profile_item_id);
                return foundProfileItem || profileItem;
            });
        }

        if (!isPartial && !isComplete) {
            delete newProfile.enrollment_id;
        }

        return newProfile;
    },

    updateProfile(profile: IProfile, saveRespProfile: IProfile): IProfile {
        console.log("updateProfile");
        profile.enrollment_profile_id = saveRespProfile.enrollment_profile_id;
        profile.enrollment_id = saveRespProfile.enrollment_id;
        // console.log("Updated profile version from ", profile.version, saveRespProfile.version);
        profile.version = saveRespProfile.version;
        return profile;
    },

    clearPrices(profile: IProfile): void {
        delete profile.listPrice;
        delete profile.customerPrice;
        delete profile.referencePrice;

        delete profile.recommendedPetOwnerPrice;
        delete profile.acceptedPetOwnerPrice;

        delete profile.specialPrice;
        delete profile.recommendedPracticePrice;
        delete profile.acceptedPracticePrice;

        delete profile.floorPrice;

        delete profile.goalTotalProfit;
        delete profile.goalQuantity;
        delete profile.goalPetOwnerCharge;
    },

    initFromTemplate(profile: IProfile, tpl: IProfileTemplate): void {
        profile.account_settings_id = tpl.account_settings_id;
        profile.template_profile_id = tpl.template_profile_id;

        // If template has already been matched to a profile, copy that data over here.
        profile.matchedProfile = tpl.matchedProfile;
        if (profile.matchedProfile) {
            profile.profile_test_code = profile.matchedProfile.test_code;
        }

        profile.species = tpl.species;
        profile.display_order = tpl.display_order;
        profile.modality = tpl.modality;
        profile.templateType = tpl.templateType;
        profile.selected = tpl.defaultSelected;
        profile.marked_for_deletion = false;

        if (profile.matchedProfile && (!profile.products || !profile.products.length) && (!profile.profileItems || !profile.profileItems.length)) {
            const defaultTests = ProfileTemplate.getDefaultTests(profile.profileTemplate);
            profile.products = ProfileUtils.getProductsForMatch(defaultTests);

            profile.profileItems = defaultTests;
        }
        profile.defaultPrice = tpl.defaultPrice;
        profile.defaultSelected = tpl.defaultSelected;
    },

    // TODO would be nice to remove this, but currently used to keep data synchronized between extraProfile and parent
    protoify(source: Object): any {
        return {
            __proto__: {
                __proto__: source,
                toJSON(): any {
                    return {
                        ...source,
                        ...this
                    };
                }
            }
        };
    },

    getSelectedProductTestCodes(profileItemList: IProfileItem[]): string[] {
        const productsForMatch = ProfileUtils.getProductsForMatch(profileItemList);
        console.log("productsForMatch=", productsForMatch);

        return productsForMatch.map((product: IProduct): string => product.test_code);
    },

    copyProfile(profile: IProfile): IProfile {
        return ProfileUtils.protoify(profile) as IProfile;
    },

    copyProduct(product: IProduct): IProduct {
        return ProfileUtils.protoify(product) as IProduct;
    },

    resetProfile(profile: IProfile): void {
        profile.profileItems = [];
        ProfileUtils.clearPrices(profile);
        delete profile.enrollment_profile_id;
        delete profile.profile_test_code;
        delete profile.matchedProfile;
        delete profile.completed;
        profile.profileItems = [];
    },

    isPriceValid(price: number | undefined | null): boolean {
        return price !== undefined && price !== null && price !== 0;
    },

    // Check if the specified profile can still be built by the specified template.
    isProfileSupported(profile: IProfile, template?: IProfileTemplate): boolean {
        if (TEMPLATE_TYPES.PCH === profile?.templateType) {
            return true;
        }

        if (!template) {
            return true;
        }
        let isGood = template.categories.every((ctg: IProfileCategory): boolean =>
            ProfileUtils.isCategorySatisfied(ctg, profile)
        );
        if (isGood) {
            // Make sure no categories are populated in the profile that aren't in the template.
            const categoryIds = template.categories.map((ctg: IProfileCategory): number => ctg.category_id);
            isGood = isGood && profile.profileItems.every((profileItem: IProfileItem): boolean => {
                if (!categoryIds.includes(profileItem.category.category_id)) {
                    console.error("Extra category defined in profile not in template: ", profileItem.category, profileItem);
                }
                return categoryIds.includes(profileItem.category.category_id)
            });
        }
        return isGood;
    },

    // Returns true if specified profile category in the template is satisfied by any selections in the profile.
    isCategorySatisfied(ctg: IProfileCategory, profile: IProfile): boolean {

        // Pull a list of selected profile items from the profile for specified category.
        const selected = profile.profileItems.filter((profileItem: IProfileItem): boolean => profileItem.category.category_id === ctg.category_id);

        // If category is required, make sure at least something has been selected in the profile
        // for this category or that the category has a default profile item defined.
        if (ctg.isSelected && !selected.length) {
            // See if required category has a default profile item.
            // If found, consider this category OK even if nothing has been selected.
            const defaultProfileItem = ctg.profileItems.find((profileItem: IProfileItem): boolean => profileItem.is_default);
            if (!defaultProfileItem) {
                console.error("No profile items are selected in profile for required category: ", ctg, profile.profileItems);
                return false;
            }
        }

        // Make sure that all selections in the profile for this category still exist in template
        const templateProfileItemIds = ctg.profileItems.map((profileItem: IProfileItem): number => profileItem.profile_item_id);
        const profileSelectionsExistInTemplate = selected.every((profileItem: IProfileItem): boolean => templateProfileItemIds.includes(profileItem.profile_item_id));
        if (!profileSelectionsExistInTemplate) {
            console.error("Selected item(s) don't match available items: ", selected, ctg);
            return false;
        }
        return true;
    },

    // Returns true if the profile's template is still in the list of available templates for this region/accountSettings
    isTemplateAvailable(profile: IProfile, templates: IProfileTemplate[]): boolean {
        return templates.some((template: IProfileTemplate): boolean =>
            profile.template_profile_id && template.active && template.template_profile_id === profile.profileTemplate.template_profile_id
        );
    },

    getCategoryMap(profile: IProfile): Record<string, string> {
        const categoryMap: Record<string, string> = {};

        const categories = profile.profileTemplate ? profile.profileTemplate.categories : profile.categories;
        for (let i = 0, ln = categories.length; i < ln; i += 1) {
            categoryMap[categories[i].developerName] = categories[i].displayName;
        }
        return categoryMap;
    },

    getTestsByModalityAndCategory(profile: IProfile, input: IProfileItem[]): IModalityCategories[] {
        // Modality->category->tests
        const resultMap: { [key: string]: { [key: string]: IProfileItem[] } } = {};
        let ctgName: string;
        let modality: string;
        let category: string;

        const categoryMap = this.getCategoryMap(profile);

        // Order by modality, then category;
        const items = input.sort((item1: IProfileItem, item2: IProfileItem): number => {
            if (item1.modality.display_order - item2.modality.display_order) {
                return item1.category.display_order - item2.category.display_order;
            }
            return item1.modality.display_order - item2.modality.display_order;
        });

        for (const pi of items) {
            ctgName = pi.category.developerName;
            category = ctgName;
            modality = pi.modality.developer_name;
            if (!resultMap[modality]) {
                resultMap[modality] = {};
            }
            if (!resultMap[modality][category]) {
                resultMap[modality][category] = [];
            }
            resultMap[modality][category].push(pi);
        }
        const result = [];
        const order: string[] = [
            "REF_LAB",
            "REF_LAB_IHD",
            "SNAP",
            "IHD"
        ];

        for (modality in resultMap) {
            const modalityMap = resultMap[modality];
            const resultItem: IModalityCategories = {
                modality,
                categories: []
            };
            if (Object.keys(modalityMap).length) {
                result.push(resultItem);
                for (category in modalityMap) {
                    resultItem.categories.push({
                        category: categoryMap[category],
                        tests: modalityMap[category]
                    });
                }
            }
        }
        result.sort((r1: IModalityCategories, r2: IModalityCategories): number =>
            order.indexOf(r1.modality) - order.indexOf(r2.modality)
        );

        return result;
    },

    getReviewConfigurations(profile: IProfile): IReviewConfigurations[] {
        return [
            {
                profile_test_code: profile.profile_test_code,
                modalityTests: ItemUtils.getTestsByModality(profile.profileItems)
            }
        ];
    },

    // Returns true if the specified profile should be exposed to the client and pdf docs.
    isVisible(profile: IProfile): boolean {
        return profile && !profile.isPetCareHeroes;
    }
};
