import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

import { CorpPricingAlgorithmService } from "./corp-pricing-algorithm.service";
import { IProfile, ProfileUtils } from "../../../shared/model/profile";
import { ErrorUtils, IPCCError } from "../../../shared/model/error/";
import { PCCError } from "@shared/model/error/";
import { PCCClientError } from "../shared/model/pcc-client-error";
import { TestMixService } from "./testmix.service";
import { IPricedMaterials, IPriceInfo, IPriceRequest, IReferencePrice, ITestPrice2, PartnerTypeEnum, IPriceResponse, IReferencePriceResp } from "../../../shared/model/service/price-service";
import { ITestMixServiceResponse } from "@shared/model/service/test-mix-service";
import { PCCSession } from "@shared/model/pcc-session";
import { IAccount, AccountUtils } from "../../../shared/model/account";
import { IProduct } from "@shared/model/product";
import { IProfileItem } from "@shared/model/profile-item";
import { ModalityEnum } from "../../../shared/model/modality";
import { IEnrollment, Enrollment } from "@shared/model/enrollment";
import { ConfirmationComponent } from "../client/components/confirmation/confirmation.component";
import { PricingMethods } from "@shared/model/price";
import { envUtils } from "@client/globals/env";

export enum SamePriceEnum {
    NO_OP, COPY, REVERT
}

const CANADA_FLOOR_PRICE_DISCOUNT = 20;

@Injectable()
export class PCCPriceService {

    public constructor(
        private http: HttpClient,
        private corpPricingAlgorithmService: CorpPricingAlgorithmService,
        private testMixService: TestMixService,
        private modalService: NgbModal,
        private translateService: TranslateService
    ) {}

    private async getProfilePrice(pricedMaterials: IPricedMaterials[], session: PCCSession, matchedProfiles?: IProduct[]): Promise<IPriceInfo> {
        console.log("getProfilePrice:", pricedMaterials);

        const countryCd = session.accountInfo.country_cd;

        if (!pricedMaterials || pricedMaterials.length === 0) {
            return {
                sapId: null,
                hasErrors: false,
                items: []
            } as IPriceInfo;
        }

        const priceRequest: IPriceRequest = {
            sapId: session.accountInfo.sap_id,
            materials: pricedMaterials,
            countryCd,
            matchedProfiles
        };
        const priceResp = await this.getPrice(priceRequest);
        console.log("service.getPrice response: ", priceResp);
        if (this.hasPricingInfo(priceResp)) {
            return priceResp.data;
        }
        if (priceResp.error) {
            throw ErrorUtils.parseError(priceResp.error);
        } else {
            throw new PCCClientError("PRICE.SYSTEM_ERROR", "No pricing errors returned for failed pricing call", null, priceResp);
        }
    }

    private async getReferencePrices(prods: IProduct[], session: PCCSession): Promise<IReferencePrice[]> {
        console.log("getReferencePrices:", prods);

        const countryCd = session.accountInfo.country_cd;

        const priceRequest: IPriceRequest = {
            bom_ids: [],
            countryCd
        };
        priceRequest.bom_ids = prods.map((prod: IProduct): string => prod.test_code);

        const priceResp = await this.getReferencePrice(priceRequest);
        console.log("service.getReferencePrice response: ", priceResp);
        if (priceResp.success === true) {
            return priceResp.data;
        }
        console.error("getReferencePrice failed, returning 0 for now.");
        const fakeResp: IReferencePrice[] = [];
        for (const prod of prods) {
            fakeResp.push({
                bom_id: prod.test_code,
                reference_price: 0
            });
        }
        return fakeResp;
    }

    /**
     * Look up the price information for the matched profile.
     * For Corp, only requires customer-specific price, which comes from SAP Order Simulate
     * For US/Canada, requires both order simulated price (for list price) and test mix (to calculate discount)
     */
    public async runPricing(profile: IProfile, session: PCCSession): Promise<boolean> {
        console.log("runPricing");
        if (!profile || !session) {
            console.error("No profile/session?");
            return false;
        }

        const pricedMaterials = this.getPricedMaterialsList(profile, session);

        ProfileUtils.clearPricing(profile);

        profile.loading = true;

        // TODO: Try and move all of this logic to backend?
        const pricingMethod = session.accountSettings.pricingMethod;
        const selectedProfiles = [
            profile
        ];
        try {
            if (pricingMethod === PricingMethods.CORP) {
                return await this.getPriceInfoCorporate(selectedProfiles, pricedMaterials, session);
            }
            if (pricingMethod === PricingMethods.CA) {
                return await this.getPriceInfoCanada(selectedProfiles, pricedMaterials, session);
            }
            if (pricingMethod === PricingMethods.US) {
                return await this.getPriceInfoUS(selectedProfiles, pricedMaterials, session);
            }
            if (pricingMethod === PricingMethods.AU) {
                return await this.getPriceInfoAU(selectedProfiles, pricedMaterials, session);
            }
            if (pricingMethod === PricingMethods.STATIC) {
                return await this.getPriceInfoStatic(selectedProfiles);
            }
            console.error("Can't retrieve price!", session);
            return false;
        } catch (e) {
            console.error("Error running pricing: ", e);
            const hasOrderBlock = this.hasOrderBlock(e);
            profile.loading = false;

            const priceErrorMessage = this.getPricingErrorMessage();
            profile.error = { message: priceErrorMessage, hasOrderBlock };
            return false;
        }
    }

    private hasOrderBlock(err: Error | IPCCError): boolean {
        let hasBlock = false;
        console.log("hasOrderBlock: ", err["code"], err.message, err["context"]);
        if (err instanceof PCCError && err.context && Array.isArray(err.context)) {
            console.log("Pricing error messages: ", err.context);
            hasBlock = err.code === "SAP.ORDER_BLOCK";
        }
        console.log("hasBlock=", hasBlock);
        return hasBlock;
    }

    private async getPriceInfoCorporate(profiles: IProfile[], pricedMaterials: IPricedMaterials[], session: PCCSession, reloadPricing = false): Promise<boolean> {
        console.log("getPriceInfoCorporate");

        // Call backend pricing service to get prices for pricedMaterials.
        const priceInfo = await this.getProfilePrice(pricedMaterials, session);
        console.log("priceInfo=", priceInfo);

        if (!priceInfo) {
            console.error("No price info back");

            const priceErrorMessage = this.getPricingErrorMessage();
            profiles.forEach((profile: IProfile): void => {
                profile.error = { message: priceErrorMessage };
            });

            return false;
        }

        profiles.forEach((profile: IProfile): void => {
            this.setProfilePriceCorp(profile, session, priceInfo, reloadPricing);
        });

        return true;
    }

    private setProfilePriceCorp(profile: IProfile, session: PCCSession, priceInfo: IPriceInfo, reloadPricing: boolean): void {

        this.assignPrices(profile, priceInfo, null);

        profile.listPrice = profile.matchedProfile.listPrice;
        profile.customerPrice = profile.matchedProfile.customerPrice;

        const selectedTests = profile.profileItems;

        profile.inhousePrice = this.calculateInhousePrice(selectedTests);
        console.log("inhousePrice=", profile.inhousePrice);

        profile.recommendedPetOwnerPrice = this.corpPricingAlgorithmService.evalCorpPrice(session.accountSettings.pricing_algorithm, profile);

        profile.customerPrice = parseFloat(`${profile.customerPrice}`) + parseFloat(`${profile.inhousePrice}`);
        profile.recommendedPetOwnerPrice = parseFloat(`${profile.recommendedPetOwnerPrice}`) + parseFloat(`${profile.inhousePrice}`);

        const wasSubmitted = session.enrollInfo.enrollmentDate !== null && session.enrollInfo.enrollmentDate !== undefined;

        // If reloadPricing is going on for an already-submitted enrollment, keep the acceptedPetOwnerPrice
        // If reloadPricing is going on for a partial enrollment, use the recommendedPetOwnerPrice
        // If reloadingPricing is not going on, use the recommendedPetOwnerPrice
        if (!profile.acceptedPetOwnerPrice || (!reloadPricing && !wasSubmitted)) {
            profile.acceptedPetOwnerPrice = profile.recommendedPetOwnerPrice;
        }

        profile.loading = false;
    }

    private async getPriceInfoCanada(profiles: IProfile[], pricedMaterials: IPricedMaterials[], session: PCCSession, reloadPricing = false): Promise<boolean> {
        console.log("getPriceInfoCanada");

        const [
            priceInfo,
            testMix
        ] = await (Promise.all([
            this.getProfilePrice(pricedMaterials, session),
            firstValueFrom(this.testMixService.getTestMix(session?.accountInfo))
        ]) as Promise<[IPriceInfo, ITestMixServiceResponse]>);

        const priceErrorMessage = this.getPricingErrorMessage();
        if (!priceInfo) {
            console.error("No price info back");
            profiles.forEach((profile: IProfile): void => {
                profile.error = { message: priceErrorMessage };
            });

            return false;
        }

        if (!testMix || !testMix.success) {
            console.error("No price info back");

            profiles.forEach((profile: IProfile): void => {
                profile.error = { message: priceErrorMessage };
            });

            return false;
        }

        profiles.forEach((profile: IProfile): void => {
            this.setProfilePriceCanada(profile, session, priceInfo, testMix, reloadPricing);
        });

        return true;
    }

    private setProfilePriceCanada(profile: IProfile, session: PCCSession, priceInfo: IPriceInfo, testMix: ITestMixServiceResponse, reloadPricing: boolean): void {

        const wasSubmitted = session.enrollInfo.enrollmentDate !== null && session.enrollInfo.enrollmentDate !== undefined;

        this.assignPrices(profile, priceInfo, null);

        profile.listPrice = profile.matchedProfile.listPrice;
        profile.customerPrice = profile.matchedProfile.customerPrice;

        if (reloadPricing && wasSubmitted) {
            profile.recommendedPracticePrice = profile.customerPrice;
            profile.acceptedPracticePrice = profile.customerPrice;
        } else {
            profile.recommendedPracticePrice = profile.listPrice * (1 - testMix.data.discount);
            profile.acceptedPracticePrice = profile.listPrice;

            // when the customerPrice is higher than the recommendedPracticePrice, use the customerPrice as the suggested
            if (profile.customerPrice !== profile.listPrice && profile.customerPrice > profile.recommendedPracticePrice) {
                profile.recommendedPracticePrice = profile.customerPrice;
            }
        }

        profile.specialPrice = profile.customerPrice || profile.listPrice;

        profile.floorPrice = this.getFloorPriceCanada(profile);

        profile.inhousePrice = this.calculateInhousePrice(profile.profileItems);

        profile.acceptedPracticePrice = parseFloat(`${profile.specialPrice}`) + profile.inhousePrice;

        profile.loading = false;
    }

    private getFloorPriceCanada(profile: IProfile): number {
        let floorPrice = Math.floor(profile.recommendedPracticePrice - profile.recommendedPracticePrice / 100 * CANADA_FLOOR_PRICE_DISCOUNT);

        // floorPrice is either the calculated/generated recommendedPracticePrice
        // and discount or customerPrice and discount.
        // When the value is the customerPrice and discount,
        // customerPrice will never be less than floor price
        // When the value is recommendedPracticePrice and discount, the
        // customerPrice could be less than the current floor price
        // in which case the floorPrice may need to be set to the
        // customerPrice
        if (profile.customerPrice < floorPrice) {
            floorPrice = profile.customerPrice;
        }
        return floorPrice;
    }

    /**
     * TODO: Pricing logic for AU
     *
     * Recommended Price = Ref price * (1 - (Current Discount + 5%))
     * ( Use reference price in place of list price)
     *
     * Floor Price = List Price * (1 - (Current Discount + 10%))
     *
     * List price comes from lims data.
     */
    private async getPriceInfoAU(profiles: IProfile[], pricedMaterials: IPricedMaterials[], session: PCCSession, reloadPricing = false): Promise<boolean> {
        console.log("getPriceInfoAU");

        const profileProducts = this.getMatchedProfiles(profiles);

        // NOTE: For AU, the only time getProfilePrice might return is for SNAP or IHD products...
        const [
            priceInfo,
            refPrices
        ] = await (Promise.all([
            this.getProfilePrice(pricedMaterials, session),
            this.getReferencePrices(profileProducts, session)
        ]) as Promise<[IPriceInfo, IReferencePrice[]]>);

        const priceErrorMessage = this.getPricingErrorMessage();

        if (!priceInfo) {
            console.error("No price info back");

            profiles.forEach((profile: IProfile): void => {
                profile.error = { message: priceErrorMessage };
            });

            return false;
        }

        if (!refPrices) {
            console.error("No reference prices back");

            profiles.forEach((profile: IProfile): void => {
                profile.error = { message: priceErrorMessage };
            });

            return false;
        }

        profiles.forEach((profile: IProfile): void => {
            this.setProfilePriceAU(profile, session, priceInfo, refPrices, reloadPricing);
        });
        return true;
    }

    private setProfilePriceAU(profile: IProfile, session: PCCSession, priceInfo: IPriceInfo, refPrices: IReferencePrice[], reloadPricing: boolean): void {
        const wasSubmitted = session.enrollInfo.enrollmentDate !== null && session.enrollInfo.enrollmentDate !== undefined;

        this.assignPrices(profile, priceInfo, refPrices);

        profile.listPrice = profile.matchedProfile.listPrice;
        profile.customerPrice = profile.referencePrice;

        if (reloadPricing && wasSubmitted) {
            profile.recommendedPracticePrice = profile.customerPrice;
        } else {
            profile.recommendedPracticePrice = profile.referencePrice * (1 - session.accountInfo.practice_discount);
        }

        profile.specialPrice = profile.recommendedPracticePrice;

        profile.floorPrice = profile.recommendedPracticePrice;

        profile.inhousePrice = this.calculateInhousePrice(profile.profileItems);

        profile.acceptedPracticePrice = parseFloat(`${profile.specialPrice}`) + profile.inhousePrice;

        profile.loading = false;
    }

    /**
     * TODO: Pricing logic for AU
     *
     * Recommended Price = Ref price * (1 - (Current Discount + 5%))
     * ( Use reference price in place of list price)
     *
     * Floor Price = List Price * (1 - (Current Discount + 10%))
     *
     * List price comes from lims data.
     */
    private async getPriceInfoStatic(profiles: IProfile[]): Promise<boolean> {
        console.log("getPriceInfoStatic");

        // No need to make back-end service calls for pricing.
        // Use the price that's already set in the template as the acceptedPracticePrice and set other values accordingly.

        profiles.forEach((profile: IProfile): void => {
            this.setProfilePriceStatic(profile);
        });
        return true;
    }

    private setProfilePriceStatic(profile: IProfile): void {
        const defaultPrice = profile.profileTemplate.defaultPrice;
        profile.listPrice = defaultPrice;
        profile.customerPrice = defaultPrice;

        profile.recommendedPracticePrice = defaultPrice;

        profile.specialPrice = defaultPrice;

        profile.floorPrice = defaultPrice;

        profile.inhousePrice = null;

        profile.acceptedPracticePrice = defaultPrice;

        profile.loading = false;
    }

    /**
     * Prices for US accounts are calculated by calling the pricing service.
     * The service in turn calls the IRL pricing lambda, which has logic to handle both RefLab profiles
     * as well as SAP Material numbers.
     */
    private async getPriceInfoUS(profiles: IProfile[], pricedMaterials: IPricedMaterials[], session: PCCSession, reloadPricing = false): Promise<boolean> {
        console.log("getPriceInfoUS");

        const profileProducts = this.getMatchedProfiles(profiles);

        const priceInfo = await this.getProfilePrice(pricedMaterials, session, profileProducts);

        if (!priceInfo) {
            const priceErrorMessage = this.getPricingErrorMessage();
            console.error("No price info back");
            profiles.forEach((profile: IProfile): void => {
                profile.error = { message: priceErrorMessage };
            });
            return false;
        }

        profiles.forEach((profile: IProfile): void => {
            this.setProfilePriceUS(profile, session, priceInfo, reloadPricing);
        });

        return true;
    }

    private setProfilePriceUS(profile: IProfile, session: PCCSession, priceInfo: IPriceInfo, reloadPricing: boolean): void {

        const wasSubmitted = session.enrollInfo.enrollmentDate !== null && session.enrollInfo.enrollmentDate !== undefined;

        this.assignPrices(profile, priceInfo, null);

        profile.listPrice = profile.matchedProfile.listPrice;
        profile.customerPrice = profile.matchedProfile.customerPrice;

        profile.specialPrice = profile.customerPrice || profile.listPrice;

        profile.inhousePrice = this.calculateInhousePrice(profile.profileItems);

        if (reloadPricing && wasSubmitted) {
            profile.recommendedPracticePrice = profile.customerPrice;
            profile.acceptedPracticePrice = profile.customerPrice;
        } else {
            profile.acceptedPracticePrice = parseFloat(`${profile.specialPrice}`) + profile.inhousePrice;
        }

        profile.loading = false;
    }

    /**
     * Check if current Session contains same matched profile code in other Profile objects.
     *   If true, check if the price information there matches this one.
     *     If false, check with user if they should apply the same prices to this one.
     *       If user says OK, update the suppled Profile with other Profile's pricing information.
     *       If user says Cancel, revert both/all Profile(s) with the same code to default values.
     Outcomes:
          1) Return NO_OP.  Situation doesn't apply - no other profiles with the same matched code, or all matching profiles have the same price info.
        2) Return PRICE_COPY.  Other profile(s) found with same matched code but different price info, User accepted prompt and old Profile's price info gets applied to supplied one.
        3) Return PRICE_REVERT.  Other profile(s) with match, but User cancels price check, resulting in old profile(s) having their edited price info wiped out in favor of default price info (taken from supplied profile).
     */
    public async samePriceCheck(enrollInfo: IEnrollment, profile: IProfile, fromEdit = false): Promise<SamePriceEnum> {
        console.log("samePriceCheck");

        const sameProfiles: IProfile[] = this.findDuplicateProfiles(enrollInfo, profile, true);
        if (sameProfiles.length === 0) {
            return SamePriceEnum.NO_OP;
        }

        const priceDiffers = sameProfiles.some((sameProfile: IProfile): boolean =>
            sameProfile.acceptedPracticePrice !== profile.acceptedPracticePrice
            || sameProfile.specialPrice !== profile.specialPrice
        );

        // We have more than one profile with the same matched profile test code.  Check if their price info matches.
        console.log("priceDiffers=", priceDiffers);
        if (!priceDiffers) {
            return SamePriceEnum.NO_OP;
        }

        // Show modal asking user: "Do you want to apply the special price that has already been
        // applied to test code [profile test code]?"
        const resp = await this.confirmSamePrice(profile.profile_test_code, fromEdit);
        console.log("confirm resp=", resp);
        if (resp) {
            return SamePriceEnum.COPY;
        }
        return SamePriceEnum.REVERT;
    }

    /**
     * Given an enrollment containing zero-many profiles and a specific profile,
     * find any other profiles in the enrollment that contain the same matched
     * profile test code but different prices.
     */
    public findDuplicateProfiles(enrollInfo: IEnrollment, profile: IProfile, diffPriceOnly: boolean): IProfile[] {
        console.log("findDuplicateProfile", diffPriceOnly);
        const sameProfiles: IProfile[] = enrollInfo.profiles
            .filter((filterProfile: IProfile): boolean =>
                filterProfile !== profile && (!filterProfile.enrollment_profile_id || filterProfile.enrollment_profile_id !== profile.enrollment_profile_id) && filterProfile.profile_test_code === profile.profile_test_code && filterProfile.specialPrice && filterProfile.specialPrice !== profile.specialPrice
            );
        console.log("sameProfiles=", sameProfiles);
        return sameProfiles;
    }

    public async confirmSamePrice(testCode: string, fromEdit: boolean): Promise<boolean> {
        console.log("confirmSamePrice");
        const ref = this.modalService.open(ConfirmationComponent, {
            size: "xl",
            windowClass: "confirmation-leaving",
            backdrop: "static",
            keyboard: false
        });
        ref.componentInstance.title = "clearSessions.samePriceTitle";
        ref.componentInstance.body = fromEdit ? "clearSessions.samePriceMsg2" : "clearSessions.samePriceMsg";
        ref.componentInstance.param = {
            profile_test_code: testCode
        };
        ref.componentInstance.closable = true;
        ref.componentInstance.button1 = {
            text: "clearSessions.button1", value: true, cls: "pcc-primary-button"
        };
        ref.componentInstance.cancelButton = null;

        try {
            const value = await ref.result;
            console.log("Confirm value=", value);
            return value.value === true;
        } catch (err) {
            console.log("modal dismissed: ", err);
            return false;
        }
    }

    /**
     * When a new profile is added with default prices that matches another profile
     *  in the enrollment whose prices have been modified by the user, copy that price
     *  over to this one.
     * Note: Assuming this check has already been performed so we only have two versions
     * of pricing, the "default" one and what a user has changed one/all matching profiles to.
     */
    public copyPrices(profile: IProfile, enrollInfo: IEnrollment): boolean {
        console.log("copyPrices");
        const sameProfiles: IProfile[] = this.findDuplicateProfiles(enrollInfo, profile, true);
        if (sameProfiles.length > 0) {
            profile.specialPrice = sameProfiles[0].specialPrice;
            profile.acceptedPracticePrice = parseFloat(`${profile.specialPrice}`) + profile.inhousePrice;
            return true;
        }
        console.error("No matching profiles, copyPrices failed");
        return false;
    }

    public revertPrices(profile: IProfile, enrollInfo: IEnrollment): boolean {
        console.log("revertPrices");
        const sameProfiles: IProfile[] = this.findDuplicateProfiles(enrollInfo, profile, true);
        if (sameProfiles.length > 0) {
            for (const sp of sameProfiles) {
                sp.specialPrice = profile.specialPrice;
                sp.acceptedPracticePrice = parseFloat(`${sp.specialPrice}`) + sp.inhousePrice;
            }
            return true;
        }
        console.error("No matching profiles, revertPrices failed");
        return false;
    }

    private calculateInhousePrice(selectedTests: IProfileItem[]): number {
        let inhousePrice = 0;
        if (!selectedTests) {
            return inhousePrice;
        }
        for (let i = 0, ln = selectedTests.length; i < ln; i++) {
            if (selectedTests[i].price) { // Assigned price from admin
                console.log("price: ", selectedTests[i].price, (typeof selectedTests[i].price));
                inhousePrice += selectedTests[i].price;
            } else if (selectedTests[i].products) { // Calculated prices for products/profiles
                inhousePrice = selectedTests[i].products.reduce((sum: number, product: IProduct): number => {
                    if (product.customerPrice) {
                        const qty = product.default_quantity || 1;
                        return sum + (product.customerPrice / qty);
                    }
                    return sum;
                }, inhousePrice);
            }
        }
        return inhousePrice;
    }

    /**
     * Re-run all pricing logic for all profiles contained in enrollment.
     * We could do this just by calling runPricing 1-many times, once per profile.
     * But attempting to optimize this so we don't plaster SAP with requests.
     * Instead, going to assemble all price-able materials, make a single call,
     * and then split them back out...
     *
     * In the case of submitted enrollments, we want to update pricing based on current pricing in
     * SAP, etc and not re-discount the already discounted price created when first submitting the
     * enrollment.  So instead of using recommended price or the saved accepted price, we'll use
     * customerPrice, since that would by now already be in SAP.  Any other pricing values (pet owner
     * pricing, floor pricing, etc), will use what was saved in the enrollment, not what comes back
     * from pricing calls.
     */
    public async reloadPricing(session: PCCSession): Promise<boolean> {
        console.log("reloadPricing", session);

        const pricedMaterials = this.getAllPricedMaterials(session);
        const selectedProfiles = Enrollment.getSelectedProfiles(session.enrollInfo);
        try {
            let pricesFound = false;
            const pricingMethod = session.accountSettings.pricingMethod;
            if (pricingMethod === PricingMethods.CORP) {
                pricesFound = await this.getPriceInfoCorporate(selectedProfiles, pricedMaterials, session, true);
            } else if (pricingMethod === PricingMethods.CA) {
                pricesFound = await this.getPriceInfoCanada(selectedProfiles, pricedMaterials, session, true);
            } else if (pricingMethod === PricingMethods.US) {
                pricesFound = await this.getPriceInfoUS(selectedProfiles, pricedMaterials, session, true);
            } else if (pricingMethod === PricingMethods.AU) {
                pricesFound = await this.getPriceInfoAU(selectedProfiles, pricedMaterials, session, true);
            } else if (pricingMethod === PricingMethods.STATIC) {
                pricesFound = await this.getPriceInfoStatic(selectedProfiles);
            } else {
                console.error("Can't retrieve price!", session);
                pricesFound = false;
            }

            return pricesFound;
        } catch (e) {
            console.error(e);
            const hasOrderBlock = this.hasOrderBlock(e);

            const priceErrorMessage = this.getPricingErrorMessage();

            console.log("Setting profiles to error...");
            for (const p of selectedProfiles) {
                p.loading = false;

                p.error = { message: priceErrorMessage, hasOrderBlock };
            }

            return false;
        }
    }

    /**
     * Price requests can be either for LIMS materials (in the case of the matched
     * profile), or IHD materials.  So need to parse that all out here so we can
     * call SAP correctly with (up to) two separate requests.
     */
    private getPricedMaterialsList(profile: IProfile, session: PCCSession): IPricedMaterials[] {
        console.log("getPricedMaterialsList: ", profile);
        let materials: IPricedMaterials[] = [];
        const results: Record<string, IPricedMaterials> = this.getPricedMaterials(profile, session.accountInfo);
        console.log("results=", results);
        materials = Object.values(results);
        console.log("materials=", materials);
        return materials;
    }

    /**
     * Price requests can be either for LIMS materials (in the case of the matched
     * profile), or IHD materials.  So need to parse that all out here so we can
     * call SAP correctly with (up to) two separate requests.
     */
    private getPricedMaterials(profile: IProfile, accountInfo: IAccount): Record<string, IPricedMaterials> {
        console.log("getPricedMaterials: ", profile.matchedProfile);
        const results: Record<string, IPricedMaterials> = {};

        if (profile.matchedProfile?.sap_material_number) {
            const { division, matchedProfileMaterials } = this.getLabPricedMaterials(profile, accountInfo);
            results[division] = matchedProfileMaterials;
        }

        profile.profileItems.forEach((pi: IProfileItem): void => {
            if ((ModalityEnum.IHD.equals(pi.modality)
                || ModalityEnum.SNAP.equals(pi.modality)) && !pi.price) {
                console.log("Checking for priced material in profile item: ", pi);
                pi.products.forEach((product: IProduct): void => {
                    const { productDivision: division, productPricedMaterials } = this.getProductPricedMaterials(product, accountInfo);
                    if (division) {
                        if (results[division]) {
                            results[division].sapMaterialNumbers = results[division].sapMaterialNumbers.concat(productPricedMaterials.sapMaterialNumbers);
                        } else {
                            results[division] = productPricedMaterials;
                        }
                    }
                });
            }
        });
        return results;
    }

    private getLabPricedMaterials(profile: IProfile, accountInfo: IAccount): { division: string, matchedProfileMaterials: IPricedMaterials } {
        const division = profile.matchedProfile.division || "VS";
        const soldTo = AccountUtils.getPartner(accountInfo, PartnerTypeEnum.SOLD_TO.value, division);
        const shipTo = AccountUtils.getPartner(accountInfo, PartnerTypeEnum.SHIP_TO.value, division);
        return {
            division,
            matchedProfileMaterials: {
                sapMaterialNumbers: [
                    profile.matchedProfile.sap_material_number
                ],
                soldToPartner: soldTo,
                shipToPartner: shipTo
            }
        };
    }

    private getProductPricedMaterials(product: IProduct, accountInfo: IAccount): { productDivision: string, productPricedMaterials: IPricedMaterials } {
        if (!product.test_code && product.sap_material_number && product.division) {
            let division = product.division;
            if ([
                "VP",
                "VV",
                "HE"
            ].includes(division)) {
                division = "CP";
            }
            return {
                productDivision: division,
                productPricedMaterials: {
                    sapMaterialNumbers: [
                        product.sap_material_number
                    ],
                    soldToPartner: AccountUtils.getPartner(accountInfo, PartnerTypeEnum.SOLD_TO.value, division),
                    shipToPartner: AccountUtils.getPartner(accountInfo, PartnerTypeEnum.SHIP_TO.value, division)
                }
            };
        }
        return null;
    }

    private assignPrices(profile: IProfile, priceInfo?: IPriceInfo, refPriceResp?: IReferencePrice[]): void {
        console.log("assignPrices", priceInfo);
        console.log("refPrices=", refPriceResp);

        if (!profile) {
            console.error("profile is null");
            return;
        }

        // Only relevant for AU pricing
        if (profile.matchedProfile && refPriceResp) {
            this.assignReferencePrice(profile, refPriceResp);
        }

        // For each priced material coming back, find the product it was sourced from.
        // It will be either the matched profile, or a product inside a profile item.

        if (priceInfo && priceInfo.items) {
            this.assignPricesToItems(profile, priceInfo);
        }
    }

    private assignReferencePrice(profile: IProfile, refPriceResp: IReferencePrice[]): void {
        console.log("profile.matchedProfile=", profile.matchedProfile);
        const refPrice = refPriceResp.find((referencePrice: IReferencePrice): boolean => referencePrice.bom_id === profile.matchedProfile.test_code);
        console.log("found refPrice=", refPrice);
        profile.referencePrice = refPrice ? refPrice.reference_price || 0 : 0;
    }

    private assignPricesToItems(profile: IProfile, priceInfo: IPriceInfo): void {
        // For each priced material coming back, find the product it was sourced from.
        // It will be either the matched profile, or a product inside a profile item.
        priceInfo.items.forEach((item: ITestPrice2): void => {
            this.assignPriceToMatchedProfile(profile, item);
            this.assignPriceToProfileItems(profile, item);
        });
    }

    // Assign pricing from lambda call to profile.
    // When enrollment has already been submitted, don't use recommended price coming back from lambda
    private assignPriceToMatchedProfile(profile: IProfile, item: ITestPrice2): void {
        console.log("assignPriceToMatchedProfile: ", profile.matchedProfile, item);
        const { matchedProfile } = profile;
        if (matchedProfile?.sap_material_number === item.sapMaterialNumber) {
            console.log("Assigning price back to matched profile...", item, matchedProfile);
            matchedProfile.listPrice = item.list || item.totalPrice;
            matchedProfile.customerPrice = item.netPrice;
            profile.floorPrice = item.floor;
            profile.recommendedPracticePrice = item.recommended;
            if (item.error) {
                this.assignErrorMessage(profile, item.error);
            }
        }
    }

    private assignPriceToProfileItems(profile: IProfile, item: ITestPrice2): void {
        profile.profileItems.forEach((pi: IProfileItem): void => {
            if (pi.products) {
                for (const p of pi.products) {
                    if (p.sap_material_number === item.sapMaterialNumber) {
                        console.log("Assigning price back to profile item product...", p);

                        p.listPrice = item.list;
                        p.customerPrice = item.netPrice;

                        if (item.error) {
                            this.assignErrorMessage(profile, item.error);
                        }
                        return;
                    }
                }
            }
        });
    }

    private assignErrorMessage(profile: IProfile, error: string): void {
        const parsedError = ErrorUtils.parseError(error);
        console.log(`item error=${parsedError}`);

        const priceErrorMessage = this.getPricingErrorMessage();

        const hasOrderBlock = this.hasOrderBlock(parsedError);

        if (profile.error) {
            console.warn("Overwriting profile error message with new.", profile.error, hasOrderBlock);
        }

        profile.error = { message: priceErrorMessage, hasOrderBlock };
    }

    private getAllPricedMaterials(session: PCCSession): IPricedMaterials[] {
        console.log("getAllPricedMaterials", session.enrollInfo.profiles);
        let materials: IPricedMaterials[] = [];

        const selectedProfiles = Enrollment.getSelectedProfiles(session.enrollInfo);

        if (selectedProfiles.length === 0) {
            console.log("No profiles in saved session, nothing to price.");
            return materials;
        }

        const results: Record<string, IPricedMaterials> = {};

        for (const profile of selectedProfiles) {
            const pricedMaterials = this.getPricedMaterials(profile, session.accountInfo);
            for (const division of Object.keys(pricedMaterials)) {
                if (results[division]) {
                    results[division].sapMaterialNumbers = [
                        ...results[division].sapMaterialNumbers,
                        ...pricedMaterials[division].sapMaterialNumbers
                    ];
                } else {
                    results[division] = pricedMaterials[division];
                }
            }
        }
        console.log("results all=", results);

        materials = Object.values(results);

        // Note that materials could contain some dups
        console.log("all materials = ", materials);

        return materials;
    }

    /**
     *Returns true if pricing response either:
     * 1) Has valid prices
     * 2) _OR_ if specific errors were returned for each item (for example a credit block).
     */
    private hasPricingInfo(priceResp: IPriceResponse): boolean {
        return priceResp &&
            (priceResp.success === true ||
                (priceResp.data && priceResp.data.items && priceResp.data.items.length && priceResp.data.hasErrors));
    }

    private getPricingErrorMessage(key = "pricing.PRICING_ERROR_MSG"): string {
        return this.translateService.instant(key, { email: envUtils.HANDLING_EMAIL });
    }

    public async getPrice(priceRequest: IPriceRequest): Promise<IPriceResponse> {
        return await firstValueFrom(
            this.http.post<IPriceResponse>("/api/price", priceRequest)
        );
    }

    public async getReferencePrice(priceRequest: IPriceRequest): Promise<IReferencePriceResp> {
        return await firstValueFrom(
            this.http.post<IReferencePriceResp>("/api/ref_price", priceRequest)
        );
    }

    public getMatchedProfiles(profiles: IProfile[]): IProduct[] {
        return profiles
            .filter((profile: IProfile): boolean => (profile.matchedProfile ? true : false))
            .map((profile: IProfile): IProduct => profile.matchedProfile);
    }
}
