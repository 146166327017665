import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, OnChanges } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { IProfile, ProfileUtils } from "@shared/model/profile";
import { EnrollUtils, IEnrollment } from "@shared/model/enrollment";
import { PCCSession } from "@shared/model/pcc-session";
import { SessionModeEnum } from "@shared/model/session-mode-enum";
import { IChampionRole } from "@shared/model/champion-role";
import { ISystemSettings } from "@shared/model/system-settings";
import { UtilService as utils } from "@shared/service/util.service";
import { IAccountRep } from "@shared/model/account";
import { AccountSettings } from "@shared/model/account-settings";
import { ISeismicLink, SelectableSeismicLink, DOC_TAGS } from "@shared/model/seismic";
import { ICustomPage, CustomPageTypes } from "@shared/model/custom-page";

import { AppFacade } from "../../../facade/app.facade";

import { PCCAlertService } from "../../../service/alert.service";
import { IProgressItem, NavService } from "../../../service/nav.service";
import { Subscription } from "rxjs";

import { DateAdapter } from "@angular/material/core";
import { envUtils } from "@client/globals/env";

@Component({
    selector: "pcc-enroll",
    templateUrl: "./enroll.component.html",
    styleUrls: [
        "./enroll.component.scss"
    ],
    // Disable View Encapsulation to make popover style work when defined locally
    encapsulation: ViewEncapsulation.None
})
export class EnrollComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

    public session: PCCSession;

    public enrollInfo: IEnrollment;

    @ViewChild("enrollForm", {
        static: false
    }) public enrollForm: NgForm;

    private sessionSub: Subscription;

    private localeSub: Subscription;

    public championRoles: IChampionRole[];

    public lockedEnrollment = false;

    public lockedMsg: string;

    public validateMsg: string;

    public vdcUser: IAccountRep;

    public allowVDCPhoneEdit = false;

    public docs: SelectableSeismicLink[] = [];

    public loadingDocs = false;

    public profileCards: IProfile[] = [];

    public hasResources = false;

    public submitEnabled = false;

    @ViewChild("vdcEmailInput")
    public vdcEmailInput: NgModel;

    private nextPage: IProgressItem = {
        name: "review.Enroll",
        link: "done",
        action: "done",
        enabled: false
    };

    public dateLocked = false;

    public submitButtonKey: string;

    // Custom page here contains documents that can be attached to final enrollment.
    public customContent: ICustomPage;

    public customPrefix: string;

    public constructor(
        private appFacade: AppFacade,
        private alertService: PCCAlertService,
        private navService: NavService,
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<any>
    ) {
    }

    private static getToday(): string {
        return (new Date()).toLocaleDateString("en-US");
    }

    public getMinIntegrationDate(): string {
        if (this.session.sessionMode === SessionModeEnum.FRESH) {
            return EnrollComponent.getToday();
        }
        return this.enrollInfo.integrationDate;
    }

    public ngAfterViewInit(): void {
        setTimeout((): void => {
            // Invoke "later" so form controls have been initialized
            this.updateButtons();
        })
    }

    private updateButtons(): void {
        const isValid = this.validate();

        // Can only submit if page is valid AND not in training mode or draft account settings
        this.submitEnabled = isValid && this.canSubmit();
    }

    private getHandlingEmail(): string {
        return this.session.accountSettings.handling_email || envUtils.HANDLING_EMAIL;
    }

    public ngOnInit(): void {

        this.initRoles();

        this.navService.setNextState(null);

        this.submitEnabled = this.canSubmit();

        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );

        this.localeSub = this.appFacade.getLocaleSubject().subscribe(
            (locale: string): void => {
                this.setLocale(locale);
            }
        );

        if (this.enrollInfo.totalGoalQuantities <= 0) {
            this.enrollInfo.totalGoalQuantities = null;
        }
    }

    public ngOnChanges(): void {
        this.updateButtons();
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
        this.localeSub.unsubscribe();
    }

    // TODO: Merge this with submit enrollment call!
    private async saveSession(): Promise<boolean> {
        try {
            this.alertService.setBusy(true, "Loading...");

            if (this.session.sessionMode !== SessionModeEnum.EXPIRED) {
                // Always update enrollment end date before leaving this screen to prevent
                // it from getting out of step with launch date (fixes DE48569).
                EnrollUtils.updateEnrollmentEndDate(this.enrollInfo);
            }

            this.updateSelectedDocuments();

            const resp = await this.appFacade.saveSession();
            console.log("saveSession resp: ", resp);

            if (resp && resp.success) {
                return true;
            }
            console.error("saveSession failed: ", resp);

            return false;
        } finally {
            this.alertService.setBusy(false);
        }
    }

    // TODO: Should be coming back in account settings.
    private async initRoles(): Promise<void> {
        const systemSettings: ISystemSettings = await this.appFacade.getSystemSettingsCached();

        this.championRoles = systemSettings.championRoles;

        this.championRoles.sort((cr1: IChampionRole, cr2: IChampionRole): number => cr1.display_order - cr2.display_order);

    }

    public compareRole(r1: IChampionRole, r2: IChampionRole): boolean {
        return r1 && r2 ? r1.champion_role_id === r2.champion_role_id : r1 === r2;
    }

    public onFormChange(): void {
        this.updateButtons();
    }

    public champRoleChanged(): void {
        this.updateButtons();
    }

    private validate(): boolean {
        this.validateMsg = null;
        let isValid = true;

        if (!this.enrollForm || !this.enrollInfo) {
            console.warn("Enrollment info not available");
            return false;
        }

        if (!this.enrollInfo.vdcUser) {
            this.validateMsg = this.translateService.instant("enroll.missing_vdc_user");
            console.warn(this.validateMsg);
            isValid = false;
        } else if (!this.enrollForm.valid) {
            console.warn("enrollForm not valid");
            const errors = this.getFormErrors();
            console.warn("form errors=", errors);
            isValid = false;
        } else if (!this.enrollInfo.launchDate || !this.enrollInfo.integrationDate) {
            console.warn("launch or integration date is null");
            isValid = false;
        } else if (this.enrollInfo.petCareHeroesEnrolled && (!this.enrollInfo.petCareHeroesNumStaff || this.enrollInfo.petCareHeroesNumStaff < 1)) {
            // If user is enrolled in PCH, then numStaff is required.
            isValid = false;
        }

        if (!this.enrollInfo?.vdcUser?.email?.trim().toLowerCase().endsWith("@idexx.com")) {
            this.vdcEmailInput.control.setErrors({ invalid: true, email: true });
            isValid = false;
        }

        console.log("isValid=", isValid);

        this.validateMsg = utils.trimToNull(this.validateMsg);

        return isValid;
    }

    private getFormErrors(): string[] {
        const errors: string[] = [];

        // Iterate through form controls and check for errors
        Object.keys(this.enrollForm.controls).forEach((controlName: string): void => {
            const control = this.enrollForm.controls[controlName];
            if (control.errors) {
                Object.keys(control.errors).forEach((errorName: string): void => {
                    errors.push(`Field '${controlName}' has error: ${errorName}`);
                });
            }
        });

        return errors;
    }

    private async setSession(session: PCCSession): Promise<void> {
        this.session = session;
        this.enrollInfo = session.enrollInfo;

        this.submitButtonKey = this.getSubmitButtonKey();

        if (this.enrollInfo?.profiles) {
            this.profileCards = ProfileUtils.filterSelectedProfiles(this.enrollInfo.profiles);
        }

        this.vdcUser = this.enrollInfo.vdcUser || {
            rep_role: "VDC"
        };
        this.enrollInfo.vdcUser = this.vdcUser;

        this.allowVDCPhoneEdit = this.vdcUser && !this.vdcUser.phone;

        this.dateLocked = (session.sessionMode !== SessionModeEnum.FRESH);

        if (this.session.sessionMode !== SessionModeEnum.FRESH) {
            this.lockedEnrollment = true;
            const key = this.session.sessionMode === SessionModeEnum.ACTIVE ? "locked-enrollment.active" : "locked-enrollment.expired";
            this.lockedMsg = this.appFacade.translate(key, { email: this.getHandlingEmail() });
        }

        await this.pullCustomContent();

        this.updateButtons();
    }

    private setLocale(locale: string): void {
        console.log("setLocale: ", locale);

        this.dateAdapter.setLocale(locale);

        this.updateDocs(locale);
    }

    // Only happens once per visit to this page.  Needed to pull thumbnails, etc from custom content.
    private async pullCustomPageContent(customPageId: number): Promise<void> {

        try {
            this.loadingDocs = true;

            const resp = await this.appFacade.getCustomPage(customPageId);
            if (resp.success) {
                this.setCustomPage(resp.data);
            } else {
                console.error("Error pulling custom page details: ", resp);
            }
        } catch (err) {
            console.error("Error pulling custom page: ", err);
        } finally {
            this.loadingDocs = false;
        }
    }

    private setCustomPage(page: ICustomPage): void {
        this.customContent = page;
        this.docs = page.links;

        this.updateDocs(this.session?.locale);
    }

    private updateDocs(locale: string): void {
        if (!this.customContent || !locale) {
            return;
        }
        console.log("updateDocs: ", locale);
        this.docs = this.getAvailableSeismicDocs(this.customContent.links, locale);

        this.hasResources = (this.docs && this.docs.length > 0);

        this.docs.forEach((link: SelectableSeismicLink): void => {
            link.selected = this.isDocumentSelected(link);
        });
    }

    // From the list of seismic docs attached to the enrollment custom page
    private getAvailableSeismicDocs(links: ISeismicLink[], locale: string) {
        // Don't show tagged docs here unless business rules are satisfied.
        return links.filter((link: ISeismicLink): boolean =>
            link.locale === locale
            && (!link.tag || this.isTaggedDocValid(link))
        );
    }

    private isDocumentSelected(doc: ISeismicLink): boolean {
        const selectedDocs = this.enrollInfo.selectedDocuments;
        if (!selectedDocs) {
            return false;
        }
        const isInSelectedDocs = selectedDocs.some((selectedDoc: ISeismicLink): boolean => selectedDoc.id === doc.id);

        const validTaggedDoc = doc.tag && this.isTaggedDocValid(doc);

        return isInSelectedDocs || (this.session.sessionMode === SessionModeEnum.FRESH && doc.isDefault) || validTaggedDoc;
    }

    private async updateSelectedDocuments(): Promise<boolean> {
        this.enrollInfo.selectedDocuments = this.docs.filter((link: SelectableSeismicLink): boolean => link.selected);
        return this.enrollInfo?.selectedDocuments.length > 0;
    }

    // Returns true if business rule related to tag is satisfied
    private isTaggedDocValid(link: ISeismicLink): boolean {
        if (link.tag === DOC_TAGS.PCH) {
            return this.includePetCareHeroesPdf();
        }
        if (link.tag === DOC_TAGS.IAUA) {
            return this.includeIAUAPdf();
        }
        console.error("Tag not valid: ", link.tag);
        return false;
    }

    private includePetCareHeroesPdf(): boolean {
        return this.session.accountSettings.flags.pch_enabled && this.enrollInfo.petCareHeroesEnrolled;
    }

    public pchEnrolledChange() {
        // User clicked on pet care heroes enrolled checkbox - update docs to show/hide any PCH-tagged documents
        // But don't lose any current selections.
        this.docs = this.getAvailableSeismicDocs(this.customContent.links, this.session.locale);

        this.hasResources = (this.docs && this.docs.length > 0);

        this.docs.forEach((link: SelectableSeismicLink): void => {
            link.selected = this.isDocumentSelected(link);
        });

        setTimeout((): void => {
            this.updateButtons();
        });
    }

    // Returns true if one or more selected profiles contains an IAUA profile.
    private includeIAUAPdf(): boolean {
        return ProfileUtils
            .filterSelectedProfiles(this.enrollInfo.profiles)
            .some((profile: IProfile): boolean => {
                const selectedTests = profile.profileItems || [];
                return ProfileUtils.containsIDEXXAnywhereUrinalysis(selectedTests);
            });
    }

    public async submitEnrollment(): Promise<void> {
        const success = await this.saveSession();
        if (success) {
            this.enrollInfo.submitted = true;

            this.navService.goNext(this.nextPage);
        }
    }

    public canSubmit(): boolean {
        if (!this.appFacade.isSubmitEnabled()) {
            console.log("Submit not enabled for Training mode!");
            return false;
        }
        return true;
    }

    private getSubmitButtonKey(): string {
        if (this.session.sessionMode === SessionModeEnum.FRESH) {
            return "enroll.Enroll";
        }
        if (this.session.sessionMode === SessionModeEnum.ACTIVE) {
            return "enroll.Enroll_Active";
        }
        if (this.session.sessionMode === SessionModeEnum.EXPIRED) {
            return "enroll.Enroll_Expired";
        }
        return "enroll.Enroll";
    }

    private async pullCustomContent(): Promise<void> {
        const customPages = AccountSettings.getCustomPages(this.session.accountSettings, CustomPageTypes.ENROLL);
        // There should only be one ENROLL custom page defined for this region/language.
        this.customContent = customPages.length > 0 ? customPages[0] : null;

        if (!this.customContent) {
            console.error("No custom page defined for Enrollment page for this account setting");
            this.alertService.showError("No custom page defined for Enrollment page for this account setting");
            return;
        }

        this.customPrefix = `CUSTOM_PAGE.${this.customContent?.id}.`;

        // Pulls down signed urls to any content and ensures the seismic doc is available.
        await this.pullCustomPageContent(this.customContent.id);
    }
}
