import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { EnrollUtils } from "@shared/model/enrollment";
import { IProduct } from "@shared/model/product";
import { IProfileItem } from "@shared/model/profile-item";
import { IProfile, ProfileUtils } from "@shared/model/profile";

import { IProfileTemplate, TEMPLATE_TYPES } from "@shared/model/profile-template";
import { IProfileCategory } from "@shared/model/profile-category";
import { IProfileMatchRequest } from "@shared/model/service/profile-match-service";

import { AppFacade } from "../../../facade/app.facade";
import { IModality, ModalityEnum } from "@shared/model/modality";

import { PCCSession } from "@shared/model/pcc-session";
import { ManagedCountries } from "@shared/model/country";

import { PCCAlertService } from "../../../service/alert.service";

import { Subscription } from "rxjs";
import googleAnalytics from "../../../analytics/googleAnalytics";

const REF_LAB_TITLE_KEY = "build-profile.REF_LAB_TITLE";
const IHD_TITLE_KEY = "build-profile.IHD_TITLE";

@Component({
    selector: "pcc-build-profile",
    templateUrl: "./build-profile.component.html",
    styleUrls: [
        "./build-profile.component.scss"
    ]
})
export class BuildProfileComponent implements OnDestroy, AfterViewInit {
    @Input() public profile: IProfile;

    @ViewChild("nameInput", {
        static: false
    }) public nameInput: ElementRef;

    @ViewChild("nameSpan", {
        static: false
    }) public nameSpan: ElementRef;

    @ViewChild("nameButton", {
        static: false
    }) public nameButton: ElementRef;

    private requestQueue: IProfileMatchRequest[] = [];

    public isProcessing = false;

    public saving = false;

    public matching = false;

    public message: string;

    public editingName = false;

    public session: PCCSession;

    public noMatch = false;

    public sessionSub: Subscription;

    public displayName = "";

    private profileTemplate?: IProfileTemplate;

    public categoryGroups: IProfileCategory[];

    public selectedTests: Record<string, IProfileItem[]> = {};

    public productsForMatch: IProduct[] = [];

    public matchedProfile: IProduct;

    public selectedModality = ModalityEnum.REF_LAB;

    public canEditName = false;

    public canDelete = false;

    public canReset = false;

    // Set profileChanged to true when matching logic is applied.
    // This will indicate that price lookup should run when the user is done.
    // If profileChanged is false, just save the profile (usually because of a name change),
    // but don't run any pricing logic.
    public profileChanged = false;

    public modalTitle = REF_LAB_TITLE_KEY;

    public saveButtonText = "build-profile.Save";

    public constructor(
        public activeModal: NgbActiveModal,
        public appFacade: AppFacade,
        public alertService: PCCAlertService
    ) {
        this.sessionSub = this.appFacade.getCurrentSession().subscribe(
            (session: PCCSession): void => {
                this.setSession(session);
            }
        );
    }

    private updateSaveButtonText(): void {
        if (this.profile && !this.profile.enrollment_profile_id && this.profile.templateType === TEMPLATE_TYPES.FLEX) {
            this.saveButtonText = "build-profile.Save";
        } else {
            this.saveButtonText = "build-profile.Update";
        }
    }

    public setSession(session: PCCSession): void {
        this.session = session;

        this.updateView(this.profile);
    }

    private updateDisplayName(): void {
        if (this.profile && this.nameInput && (this.profile.completed || this.isTemplatePreconfig())) {
            this.nameInput.nativeElement.textContent = this.profile.display_name;
        }
    }

    public ngAfterViewInit(): void {
        this.updateDisplayName();
    }

    public setProfile(slot: IProfile): void {
        console.log("setProfile: ", slot);
        if (!slot) {
            return;
        }

        // Copy over profile so edit in place, but won't affect the real profile unless user saves changes.
        this.profile = { ...slot };

        this.updateView(this.profile);
    }

    // Pulls profile data from profile for editing - without risking changing the
    // profile itself if user hits cancel...
    public updateView(profile: IProfile): void {
        console.log("updateView", profile);

        if (!this.session || !this.profile) {
            console.log("Waiting on data...");
            return;
        }

        this.profileTemplate = profile.profileTemplate ? {
            ...profile.profileTemplate
        } : profile;

        if (profile.completed || this.isTemplatePreconfig()) {
            this.displayName = profile.display_name;

            this.updateDisplayName();
        }

        if (this.profileTemplate.templateType === TEMPLATE_TYPES.FLEX) {
            this.canDelete = true;
            this.canReset = false;
        } else {
            this.canReset = true;
            this.canDelete = false;
        }

        this.canEditName = this.session.accountSettings.flags.can_edit_profile_name;

        this.categoryGroups = this.profileTemplate.categories;

        this.matchedProfile = profile.matchedProfile;
        this.noMatch = (this.matchedProfile == null);

        this.selectedModality = ModalityEnum.forModality(profile.modality) === ModalityEnum.IHD ? ModalityEnum.IHD : ModalityEnum.REF_LAB;

        this.updateSaveButtonText();

        this.updateModalTitle();

        this.categoryGroups.sort((c1: IProfileCategory, c2: IProfileCategory): number =>
            (c1.display_order > c2.display_order ? 1 : -1)
        );

        this.selectedTests = this.getSelectedTests(profile);

        if (!profile.matchedProfile || !ProfileUtils.isValid(profile)) {
            this.matchProfile();
        }

        delete profile.error;

        console.log("Done with build-profile.updateView");
    }

    private getSelectedTests(profile: IProfile): Record<string, IProfileItem[]> {
        const selectedTests: Record<string, IProfileItem[]> = this.selectedTests;

        // If a profile has already been built, only use the selected profile items it contains,
        // don't go looking into the template for any default selected items.
        // Otherwise, build up selected tests from what the template has for defaults.
        const hasSelections = profile.profileItems?.length > 0;

        this.categoryGroups.forEach((ctg: IProfileCategory): void => {
            ctg.profileItems = ctg.profileItems || [];

            if (hasSelections) {
                const stList = ProfileUtils.getSelectedTests(profile, ctg);
                if (stList) {
                    console.log("Re-selecting selected test(s): ", stList);
                    selectedTests[ctg.developerName] = stList;
                }
            } else {
                const defaultItem = ctg.profileItems.find((profileItem: IProfileItem): boolean =>
                    profileItem.is_default
                );
                if (defaultItem) {
                    selectedTests[ctg.developerName] = [defaultItem];
                }
            }
        });
        return selectedTests;
    }

    public editName(): void {
        this.editingName = !this.editingName;
        googleAnalytics.registerEvent("edit_profile_name", "clickEvent");
        requestAnimationFrame((): void => {
            this.nameInput.nativeElement.focus();
        });
    }

    public modalClicked(event: Event): void {
        if (!this.editingName) {
            return;
        }

        if (event.target === this.nameInput.nativeElement
            || event.target === this.nameSpan.nativeElement
            || this.nameButton.nativeElement.contains(event.target)) {
            return;
        }
        this.editingName = false;
    }

    /**
     * Save any changes in the Profile here, including a change to profile name or
     * selected profile items/match results.
     */
    public async save(): Promise<boolean> {
        try {
            this.alertService.setBusy(true, this.appFacade.translate("build-profile.saving"));

            if (!this.validate()) {
                return false;
            }

            delete this.message;

            // Update profile with latest selections, display name, match results before saving...
            this.updateProfile();

            if (!this.profile.completed) {
                this.profile.completed = true;
                console.log("Calling EnrollUtils.addProfile");
                EnrollUtils.addProfile(this.session.enrollInfo, this.profile);
            }

            // Auto-select flex profiles when they're first configured.
            this.profile.selected = this.profile.selected ?? (this.profileTemplate.templateType === TEMPLATE_TYPES.FLEX && !this.profile.enrollment_profile_id);

            this.saving = true;

            console.log(`profileChanged = ${this.profileChanged}`);

            // Editing a copy of the original profile in place here.
            // So need to replace the one in the session before calling save.
            // (submitProfile actually calls saveEnrollment in the current code - tech debt)
            EnrollUtils.replaceProfile(this.profile, this.session.enrollInfo);

            // If the matched profile has changed (user has selected a
            // different combination of profile items, or this is the first time the
            // matching ran), then look up the pricing for the matched profile.
            // If only the profile name changed, no need to re-check pricing.
            const saved2 = await this.appFacade.submitProfile(this.profile, this.session.enrollInfo, this.session, this.profileChanged);

            if (saved2) {
                this.closeWindow();
            }

            return saved2;
        } catch (err) {
            console.error("Error saving", err);
            this.message = "Error saving profile.  Please try again.";
            if (!this.profile.error) {
                this.profile.error = { message: this.message };
            }
            return false;
        } finally {
            this.saving = false;
            this.alertService.setBusy(false);
        }
    }

    private updateProfile(): void {
        const finalSelections = this.getTestList(this.selectedTests);

        this.profile.display_name = this.displayName || this.profile.display_name || "";
        // TODO: Clean up what gets stored here.  This is too messy.  Determine what is really needed...
        // A perhaps better way would be to use an in-flight Profile that's just a
        // copy of the original and contains all the data, but won't corrupt the
        // original if a user cancels changes.
        this.profile.profileItems = finalSelections;
        this.profile.profile_test_code = this.matchedProfile ? this.matchedProfile.test_code : null;
        this.profile.matchedProfile = this.matchedProfile;
        this.profile.products = this.productsForMatch;

        this.profile.modality = this.getModalityFromSelected();

        this.profile.profileTemplate = this.profileTemplate;
        this.profile.template_profile_id = this.profileTemplate.template_profile_id;
    }

    private validate(): boolean {
        if (!this.matchedProfile) {
            console.error("matchedProfile not defined!");
            return false;
        }

        if (!this.matchedProfile.sap_material_number && ManagedCountries.AU !== this.session.countryCd) {
            console.error("SAP Material Number is missing: ", this.matchedProfile);
            this.message = "Unable to retrieve pricing information.  Missing SAP Material Number";
            return false;
        }

        return true;
    }

    public closeWindow(): void {
        console.log("closeWindow");
        this.activeModal.close({
            saved: true, profile: this.profile
        });
    }

    public profileDeleted(): void {
        console.log("profileDeleted");
        this.activeModal.close({
            deleted: true,
            saved: false,
            profile: this.profile
        });
    }

    public profileReset(): void {
        console.log("profileReset");
        this.activeModal.close({
            reset: true,
            profile: this.profile
        });
    }

    // Get list of selected tests without categories
    private getTestList(tests: Record<string, IProfileItem[]>): IProfileItem[] {
        let testList: IProfileItem[] = [];
        if (tests) {
            for (const ctgName in tests) {
                if (tests[ctgName]) {
                    testList = testList.concat(tests[ctgName]);
                }
            }
        }
        console.log("Result of getTestList: ", testList);
        return testList;
    }

    public cancel(): void {
        this.activeModal.dismiss("Cancel");
    }

    public ngOnDestroy(): void {
        this.sessionSub.unsubscribe();
    }

    public testSelected(ctg: IProfileCategory, selectedTests: IProfileItem[]): void {
        this.selectedTests[ctg.developerName] = selectedTests;

        this.matchProfile();
    }

    private async matchProfile(): Promise<void> {
        console.log("matchProfile");

        delete this.matchedProfile;
        delete this.noMatch;

        this.profileChanged = true;

        const limsNames = this.appFacade.getDefaultLims(this.session);

        const profileItemList = this.getTestList(this.selectedTests);

        this.productsForMatch = ProfileUtils.getProductsForMatch(profileItemList);

        const selectedProductTestCodes = this.productsForMatch.map((product: IProduct): string => product.test_code);
        console.log("selectedProductTestCodes=", selectedProductTestCodes);

        const profileRequest: IProfileMatchRequest = {
            limsNames,
            testCodes: selectedProductTestCodes
        };

        // Debounce requests so they execute in the proper order and reduce the number of calls to
        // the backend to only the "latest" call.
        this.enqueueRequest(profileRequest);
    }

    private async callMatchProfile(profileRequest: IProfileMatchRequest): Promise<void> {
        this.matching = true;

        try {
            const resp = await this.appFacade.findMatchingProfile(profileRequest);
            console.log("Response to findMatchingProfile: ", resp);
            if (resp.success) {
                this.matchedProfile = resp.profile;

                this.noMatch = false;
            } else {
                console.error("Error calling findMatchingProfile: ", profileRequest, resp);
                this.noMatch = true;
            }
        } catch (err) {
            console.error("Error calling findMatchingProfile: ", err);
        } finally {
            this.matching = false;
        }
    }

    public getModalityFromSelected(): IModality {
        let derivedModality: ModalityEnum;

        if (ModalityEnum.IHD === this.selectedModality) {
            derivedModality = ModalityEnum.IHD;
        } else {
            const selectedList = this.getTestList(this.selectedTests);
            for (const pi of selectedList) {
                if (ModalityEnum.SNAP.equals(pi.modality)) {
                    derivedModality = ModalityEnum.SNAP;
                    break;
                }
            }
            if (!derivedModality) {
                derivedModality = ModalityEnum.REF_LAB;
            }
        }

        if (!derivedModality) {
            console.error("derivedModality not found: ", this.selectedTests);
            derivedModality = ModalityEnum.REF_LAB;
        }

        return derivedModality.getModality(this.session.systemSettings.modalities);
    }

    public async deleteClicked(): Promise<boolean> {
        console.log("deleteClicked: ", this.profile);

        try {
            const success = await this.appFacade.deleteProfile(this.profile, this.session.enrollInfo, false);

            if (success) {
                this.profileDeleted();
                return true;
            }
        } catch (err) {
            console.error("Error deleting profile: ", err);
        }
        return false;
    }

    // Clicking Reset button from build profile dialog is the same behind the scenes as a delete.
    // Removes from the enrollment.
    // Removes any "extra" profile from IAUA
    // Saves enrollment.
    // Closes dialog
    // Returns user to Profiles screen, where the preconfig template will be offered as a fresh choice
    // since no profile will be found that corresponds to this template.
    public async resetClicked(): Promise<boolean> {
        console.log("resetClicked: ", this.profile);

        try {
            const success = await this.appFacade.deleteProfile(this.profile, this.session.enrollInfo, true);
            if (success) {
                this.profileReset();
                return true;
            }
        } catch (err) {
            console.error("Error resetting profile: ", err);
        }

        return false;
    }

    public setDisplayName(event: Event): void {
        this.displayName = (<HTMLInputElement>event.target).value;
    }

    private updateModalTitle(): void {
        this.modalTitle = this.selectedModality === ModalityEnum.REF_LAB ? REF_LAB_TITLE_KEY : IHD_TITLE_KEY;
    }

    private isTemplatePreconfig(): boolean {
        return this.profileTemplate !== null && this.profileTemplate !== undefined && this.profileTemplate.templateType === TEMPLATE_TYPES.PRECONFIG;
    }

    private enqueueRequest(request: IProfileMatchRequest): void {
        this.requestQueue.push(request);
        this.processQueue();
    }

    private async processQueue(): Promise<void> {
        console.log("processQueue");
        if (this.isProcessing) {
            console.log("processQueue: isProcessing is true");
            return;
        }

        this.isProcessing = true;

        try {
            // Only keep the latest request in the queue
            const currentRequest = this.requestQueue.pop();

            if (currentRequest) {
                // Clear the queue to ensure only new requests that come in after this one are processed.
                this.requestQueue = [];

                await this.callMatchProfile(currentRequest);
            }

            // Make sure isProcessing is set to false before recursively calling processQueue or it will be a no-op
            this.isProcessing = false;

            if (this.requestQueue.length) {
                this.processQueue();
            }
        } catch (error) {
            console.error("Error processing request:", error);
            this.isProcessing = false;
        } finally {
            console.log("processQueue: Done");
        }
    }
}
